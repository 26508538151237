import React, { Component } from "react";
import "./Experts.css";
import Cookies from "./Cookies";

class Experts extends Component {
  componentWillMount() {}
  componentDidMount() {}

  render() {
    return (
      <div className="ContainerExperts">
        <div className="ContainerTitre">
          <div className="TitreExperts"> NOS EXPERTS / COMITÉ DE RÉDACTION</div>

          <div className="Support">
            {" "}
            Un problème ou une question technique ? Contactez notre équipe
            support :{" "}
            <strong>
              {" "}
              <a href="mailto:support@thewhitecoats.com">
                support@thewhitecoats.com
              </a>{" "}
            </strong>
          </div>
        </div>

        <div className="ContainerCarre">
          <div className="carreExperts">
            <div className="nomExperts"> Dr. Aurélie SWALDUZ</div>
            <div className="centreExperts">
              {" "}
              Oncologue Thoracique
              <br />
              Centre Léon Bérard - Lyon
              <br /> <span style={{ color: "transparent" }}> .</span>
            </div>
            <img
              src="https://thewhitecoats.com/IMG/Expertswhitecoats/Aur%C3%A9lie-3.png"
              className="imagesExperts"
            />
          </div>

          <div className="carreExperts">
            <div className="nomExperts">Dr. Agathe SEGUIN</div>
            <div className="centreExperts">
              {" "}
              Chirurgienne thoracique <br />
              Institut Mutualiste <br />
              Montsouris - Paris
            </div>
            <img
              src="https://thewhitecoats.com/IMG/Expertswhitecoats/Agathe%20SEGUIN.png"
              className="imagesExperts"
            />
          </div>

          <div className="carreExperts">
            <div className="nomExperts">Dr. Charles RICORDEL</div>
            <div className="centreExperts">
              {" "}
              Pneumologue <br />
              Oncologue thoracique
              <br /> CHU de Rennes{" "}
            </div>
            <img
              src="https://thewhitecoats.com/IMG/Expertswhitecoats/Charles%20RICORDEL.png"
              className="imagesExperts"
            />
          </div>

          <div className="carreExperts">
            <div className="nomExperts">Dr. Lionel FALCHERO</div>
            <div className="centreExperts">
              {" "}
              Pneumologue
              <br />
              Oncologue thoracique <br /> Hôpitaux NO - Villefranche
            </div>
            <img
              src="https://thewhitecoats.com/IMG/Expertswhitecoats/Lionel%20FALCHERO.png"
              className="imagesExperts"
            />
          </div>

          <div className="carreExperts">
            <div className="nomExperts">Pr. Michaël DURUISSEAUX</div>
            <div className="centreExperts">
              {" "}
              Pneumologue <br />
              Oncologue thoracique <br /> Hopital Louis Pradel - Bron
            </div>
            <img
              src="https://thewhitecoats.com/IMG/Expertswhitecoats/Michael%20DURUISSEAUX.png"
              className="imagesExperts"
            />
          </div>

          <div className="carreExperts">
            <div className="nomExperts">Pr. Sylvie LANTUEJOUL</div>
            <div className="centreExperts">
              {" "}
              Anatomo-cyto-pathologiste <br />
              Centre Léon Bérard - Lyon
              <br /> <span style={{ color: "transparent" }}> .</span>
            </div>
            <img
              src="https://thewhitecoats.com/IMG/Expertswhitecoats/Sylvie%20LANTUEJOUL.png"
              className="imagesExperts"
            />
          </div>
          <div className="carreExperts">
            <div className="nomExperts">Pr. Virginie WESTEEL</div>
            <div className="centreExperts">
              {" "}
              Pneumologue <br /> Oncologue thoracique <br />
              CHU de Besançon
            </div>
            <img
              src="https://thewhitecoats.com/IMG/Expertswhitecoats/Virginie%20WESTEEL.png"
              className="imagesExperts"
            />
          </div>
        </div>

        <Cookies />
      </div>
    );
  }
}

export default Experts;
