import React, { Component } from "react";
import "./white.css";
import ContainerRegister from "./ContainerRegister";
import Experts from "./Container/Experts";
import Deconnection from "./Container/Deconnection";
import ReactPlayer from "react-player";
import Video from "./Container/Video";
import Select, { StylesConfig } from "react-select";
import axios from "axios";
import Comment from "./Container/Comment";
import ProgressBar from "@ramonak/react-progress-bar";
import StarRatings from "react-star-ratings";
import screenfull from "screenfull";

/*
import { io } from "socket.io-client";
var socket = io("https://serveur.thewhitecoats.com", {
  transports: ["polling"],
  path: "/socket.io",
});*/

class white extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      connected: false,
      page: 0,
      prenom: "",
      nom: "",
      emailP: "",
      emailS: "",
      specialite: "",
      centre: "",
      centers: [],
      specialites: [],
      etatPopupSuppression: false,
      Token: "",
      stateUrl: 0,
      timeStampE1: 0,
      timeStampE2: 0,
      timeStampE3: 0,
      timeStampE4: 0,
      porcentage1: 0,
      porcentage2: 0,
      porcentage3: 0,
      porcentage4: 0,
      partager: 0,
      evaluer: 0,
      rating: 0,
      evaluerE1: 0,
      evaluerE2: 0,
      evaluerE3: 0,
      evaluerE4: 0,

      nbEvaluerE1: 0,
      nbEvaluerE2: 0,
      nbEvaluerE3: 0,
      nbEvaluerE4: 0,

      userNoteE1: 0,
      userNoteE2: 0,
      userNoteE3: 0,
      userNoteE4: 0,

      mousse: false,
      copie: 0,
      partager2: 0,

      nbVuesE1: 0,
      nbVuesE2: 0,
      nbVuesE3: 0,
      nbVuesE4: 0,
    };
    this.currentStamp = 0;
    this.maxStamp = 0;
    this.lastStamp = 1000;

    this.handleVideo = this.handleVideo.bind(this);
    this.handleRetour = this.handleRetour.bind(this);

    this.handleChangePrenom = this.handleChangePrenom.bind(this);
    this.handleChangeNom = this.handleChangeNom.bind(this);
    this.handleChangeEmailP = this.handleChangeEmailP.bind(this);
    this.handleChangeEmailS = this.handleChangeEmailS.bind(this);
    this.onSeek = this.onSeek.bind(this);
    this.onStart = this.onStart.bind(this);
    this.onProgress = this.onProgress.bind(this);
  }

  componentWillMount() {}
  componentDidMount() {
    /*   window.addEventListener("beforeunload", (ev) => {
          ev.preventDefault();
          if (this.state.stateUrl == 1) {
            localStorage.setItem("timeStampE1", this.state.timeStampE1);
            this.state.porcentage1 =
              (localStorage.getItem("timeStampE1") * 100) / 819;
          } else if (this.state.stateUrl == 2) {
            localStorage.setItem("timeStampE2", this.state.timeStampE2);

            this.state.porcentage2 =
              (localStorage.getItem("timeStampE2") * 100) / 663;
          } else if (this.state.stateUrl == 3) {
            localStorage.setItem("timeStampE3", this.state.timeStampE3);

            this.state.porcentage3 =
              (localStorage.getItem("timeStampE3") * 100) / 518;
          } else if (this.state.stateUrl == 4) {
            localStorage.setItem("timeStampE4", this.state.timeStampE4);

            this.state.porcentage4 =
              (localStorage.getItem("timeStampE4") * 100) / 733;
          } else {
          }

          this.setState({
            page: 0,
            rating: 0,
          });
          return (ev.returnValue = "Êtes-vous sûr de vouloir fermer ?");
        })*/
    setTimeout(() => {
      var Token = localStorage.getItem("Token");

      this.setState({ Token: Token });
      //console.log("token:" + Token);
      /*
      if (localStorage.getItem("Token")) {
        console.log("authentificate***************************************");

        socket.emit("authentificate", Token, function (data) {
          console.log("authentificate");
          console.log(data);
        });
      }*/

      axios
        .post(`https://serveur.thewhitecoats.com/users/fetchUserWithToken`, {
          token: Token,
        })
        .then((res) => {
          this.setState({
            prenom: res.data.data.prenom,
            nom: res.data.data.nom,
            emailP: res.data.data.email,
            emailS: res.data.data.email_sec,
            specialite: res.data.data.specialite,
            centre: res.data.data.centre,
          });
          // console.log(res.data);
        })
        .catch((err) => {
          //alert("Token invalide");
          // console.log(err);
        });

      // TimeStamp videos

      if (localStorage.getItem("timeStampE1")) {
        this.state.porcentage1 =
          (localStorage.getItem("timeStampE1") * 100) / 819;
        this.setState({ timeStampE1: localStorage.getItem("timeStampE1") });
      }
      if (localStorage.getItem("timeStampE2")) {
        this.state.porcentage2 =
          (localStorage.getItem("timeStampE2") * 100) / 663;
        this.setState({ timeStampE2: localStorage.getItem("timeStampE2") });
      }
      if (localStorage.getItem("timeStampE3")) {
        this.state.porcentage3 =
          (localStorage.getItem("timeStampE3") * 100) / 518;
        this.setState({ timeStampE3: localStorage.getItem("timeStampE3") });
      }
      if (localStorage.getItem("timeStampE4")) {
        this.state.porcentage4 =
          (localStorage.getItem("timeStampE4") * 100) / 733;
        this.setState({ timeStampE4: localStorage.getItem("timeStampE4") });
      }
    }, 100);

    //FetchCentre

    axios
      .get(`https://serveur.thewhitecoats.com/users/fetchCenter`, {})
      .then((res) => {
        const options = res.data.data.map((data, index) => {
          return {
            label: data.nom,
            value: data.nom,
            key: index,
          };
        });
        this.setState({
          centers: options,
        });

        this.state.centers.unshift(
          {
            label: "Autre Centre en France ou en libéral",
            value: "Autre Centre en France ou en libéral",
            key: 149,
          },
          {
            label: "Médecin étranger",
            value: "Médecin étranger",
            key: 150,
          }
        );
      })

      .catch((err) => {
        //alert("Token invalide");
        //console.log(err);
      });

    //FetchCentre fetchSpe

    axios
      .get(`https://serveur.thewhitecoats.com/users/fetchSpe`, {})
      .then((res) => {
        const options2 = res.data.data.map((data, index) => {
          return {
            label: data.spe,
            value: data.spe,
            key: index,
          };
        });
        this.setState({
          specialites: options2,
        });
        // console.log(res.data.data);
        // console.log(this.state.specialites);
        this.state.specialites.unshift(
          {
            label: "Interne",
            value: "Interne",
            key: 71,
          },
          {
            label: "étudiant en faculté",
            value: "étudiant en faculté",
            key: 72,
          },
          {
            label: "Industrie",
            value: "Industrie",
            key: 73,
          },
          {
            label: "Autre",
            value: "Autre",
            key: 74,
          }
        );
      })

      .catch((err) => {
        //alert("Token invalide");
        //console.log(err);
      });

    var email = localStorage.getItem("Token");

    if (email != null) {
      this.setState({ connected: true });
    }

    // Get all notation

    axios
      .get(`https://serveur.thewhitecoats.com/video/allVideoNotation`, {})
      .then((res) => {
        console.log("all vidéo");

        console.log(res.data.data);
        this.setState({
          evaluerE1: res.data.data.Episode1.moyenne,
          nbEvaluerE1: res.data.data.Episode1.total,
          nbVuesE1: res.data.data.Episode1.vue,

          evaluerE2: res.data.data.Episode2.moyenne,
          nbEvaluerE2: res.data.data.Episode2.total,
          nbVuesE2: res.data.data.Episode2.vue,

          evaluerE3: res.data.data.Episode3.moyenne,
          nbEvaluerE3: res.data.data.Episode3.total,
          nbVuesE3: res.data.data.Episode3.vue,

          evaluerE4: res.data.data.Episode4.moyenne,
          nbEvaluerE4: res.data.data.Episode4.total,
          nbVuesE4: res.data.data.Episode4.vue,
        });
      })

      .catch((err) => {
        //alert("Token invalide");
        //console.log(err);
      });
  }

  handleFullscreen = () => {
    if (screenfull.isEnabled) {
      screenfull.toggle();
    } else {
      console.log("yes");
    }
  };

  Evaluder() {
    console.log(this.state.stateUrl);
    var data = null;

    var nomVideo = "";

    if (this.state.stateUrl == 1) {
      nomVideo = "Episode1";
    } else if (this.state.stateUrl == 2) {
      nomVideo = "Episode2";
    } else if (this.state.stateUrl == 3) {
      nomVideo = "Episode3";
    } else {
      nomVideo = "Episode4";
    }

    data = {
      video: nomVideo,
      note: this.state.rating,
      token: this.state.Token,
    };

    console.log(this.state.rating);

    if (data) {
      axios
        .post(`https://serveur.thewhitecoats.com/video/notation`, {
          video: nomVideo,
          note: this.state.rating,
          token: this.state.Token,
        })
        .then((res) => {
          console.log(res.status);
        })
        .catch((err) => {
          alert("Erreur, veuillez réessayer ");
          console.log(err);
        });
    }
  }

  changeRating = (newRating, name) => {
    this.setState({
      rating: newRating,
    });

    setTimeout(() => {
      this.Evaluder();
    }, "100");
    setTimeout(() => {
      this.handleCloseEvaluer();
    }, "3000");
  };

  ref = (player) => {
    this.player = player;
  };
  onSeek(second) {
    //console.log("onSeek");
    if (second < this.maxStamp) {
      this.player.seekTo(this.maxStamp);
    }
  }

  onStart() {
    setTimeout(() => {
      if (this.state.stateUrl == 1 && localStorage.getItem("timeStampE1")) {
        this.player.seekTo(localStorage.getItem("timeStampE1"));
      } else if (
        this.state.stateUrl == 2 &&
        localStorage.getItem("timeStampE2")
      ) {
        this.player.seekTo(localStorage.getItem("timeStampE2"));
      } else if (
        this.state.stateUrl == 3 &&
        localStorage.getItem("timeStampE3")
      ) {
        this.player.seekTo(localStorage.getItem("timeStampE3"));
      } else if (
        this.state.stateUrl == 4 &&
        localStorage.getItem("timeStampE4")
      ) {
        this.player.seekTo(localStorage.getItem("timeStampE4"));
      } else {
      }
    }, "200");

    // console.log("onStart");
  }

  onProgress(timeStamp) {
    var Token = localStorage.getItem("Token");

    if (this.state.stateUrl == 1) {
      this.setState({
        timeStampE1: timeStamp.playedSeconds,
      });

      axios
        .post(`https://serveur.thewhitecoats.com/video/updateVideo`, {
          video: "Episode1",
          token: Token,
          timestamp: timeStamp.playedSeconds,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          //alert("Erreur !");
          //console.log(err);
        });
    } else if (this.state.stateUrl == 2) {
      this.setState({
        timeStampE2: timeStamp.playedSeconds,
      });
      console.log(this.state.timeStampE2);
      axios
        .post(`https://serveur.thewhitecoats.com/video/updateVideo`, {
          video: "Episode2",
          token: Token,
          timestamp: timeStamp.playedSeconds,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          //alert("Erreur !");
          //console.log(err);
        });
    } else if (this.state.stateUrl == 3) {
      this.setState({
        timeStampE3: timeStamp.playedSeconds,
      });
      console.log(this.state.timeStampE3);
      axios
        .post(`https://serveur.thewhitecoats.com/video/updateVideo`, {
          video: "Episode3",
          token: Token,
          timestamp: timeStamp.playedSeconds,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          //alert("Erreur !");
          //console.log(err);
        });
    } else if (this.state.stateUrl == 4) {
      this.setState({
        timeStampE4: timeStamp.playedSeconds,
      });
      console.log(this.state.timeStampE4);
      axios
        .post(`https://serveur.thewhitecoats.com/video/updateVideo`, {
          video: "Episode4",
          token: Token,
          timestamp: timeStamp.playedSeconds,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          //alert("Erreur !");
          //console.log(err);
        });
    } else {
    }

    // console.log(timeStamp.playedSeconds);
  }

  handleSocketAuthentificat = (event) => {
    /*
    socket.emit("authentificate", this.state.Token, function (data) {
      console.log("authentificate");
      console.log(data);
    });*/
  };

  handleVideo = (event) => {
    var Token = localStorage.getItem("Token");

    this.setState({
      page: 1,
      stateUrl: 1,
    });

    axios
      .post(`https://serveur.thewhitecoats.com/video/startVideo`, {
        video: "Episode1",
        token: Token,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        //alert("Erreur !");
        //console.log(err);
      });
  };

  handleVideoE2 = (event) => {
    var Token = localStorage.getItem("Token");
    this.setState({
      page: 1,
      stateUrl: 2,
    });

    axios
      .post(`https://serveur.thewhitecoats.com/video/startVideo`, {
        video: "Episode2",
        token: Token,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        //alert("Erreur !");
        //console.log(err);
      });
  };

  handleVideoE3 = (event) => {
    var Token = localStorage.getItem("Token");
    this.setState({
      page: 1,
      stateUrl: 3,
    });
    axios
      .post(`https://serveur.thewhitecoats.com/video/startVideo`, {
        video: "Episode3",
        token: Token,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        //alert("Erreur !");
        //console.log(err);
      });
  };
  handleVideoE4 = (event) => {
    var Token = localStorage.getItem("Token");
    this.setState({
      page: 1,
      stateUrl: 4,
    });
    axios
      .post(`https://serveur.thewhitecoats.com/video/startVideo`, {
        video: "Episode4",
        token: Token,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        //alert("Erreur !");
        //console.log(err);
      });
  };

  handleRetour(event) {
    if (this.state.stateUrl == 1) {
      localStorage.setItem("timeStampE1", this.state.timeStampE1);
      this.state.porcentage1 =
        (localStorage.getItem("timeStampE1") * 100) / 819;
    } else if (this.state.stateUrl == 2) {
      localStorage.setItem("timeStampE2", this.state.timeStampE2);

      this.state.porcentage2 =
        (localStorage.getItem("timeStampE2") * 100) / 663;
    } else if (this.state.stateUrl == 3) {
      localStorage.setItem("timeStampE3", this.state.timeStampE3);

      this.state.porcentage3 =
        (localStorage.getItem("timeStampE3") * 100) / 518;
    } else if (this.state.stateUrl == 4) {
      localStorage.setItem("timeStampE4", this.state.timeStampE4);

      this.state.porcentage4 =
        (localStorage.getItem("timeStampE4") * 100) / 733;
    } else {
    }

    this.setState({
      page: 0,
      rating: 0,
    });

    this.componentDidMount();
  }

  handleMousse = (e) => {
    e.preventDefault();

    var timeout;

    this.setState({ mousse: true });
    clearTimeout(timeout);

    if (timeout == undefined && this.state.mousse == false) {
      (() => {
        timeout = setTimeout(() => this.setState({ mousse: false }), 4000);
      })();
    }

    console.log(this.state.mousse);
  };

  handleInfo = (page) => {
    //console.log("page :" + page);
  };

  handleInfo2 = (page) => {
    this.setState({ page: page });
    //console.log("page :" + page);
  };

  handleClosePartage = (e) => {
    this.setState({ partager: 0 });
  };

  handleClosePartage2 = (e) => {
    this.setState({ partager2: 0 });
  };

  handleCloseEvaluer = (e) => {
    this.setState({ evaluer: 0 });
  };

  handleOpenPartage = (e) => {
    this.player.playing = false;
    this.setState({ partager: 1 });
  };

  handleOpenPartage2 = (e) => {
    this.setState({ partager2: 1 });
  };

  handleOpenEvaluer = (e) => {
    var Token = localStorage.getItem("Token");
    this.setState({ evaluer: 1 });
    axios
      .post(`https://serveur.thewhitecoats.com/video/userVideoDatas`, {
        token: Token,
      })
      .then((res) => {
        if (this.state.stateUrl == 1) {
          this.setState({
            rating: res.data.videoData.Episode1.note,
          });
        } else if (this.state.stateUrl == 2) {
          this.setState({
            rating: res.data.videoData.Episode2.note,
          });
        } else if (this.state.stateUrl == 3) {
          this.setState({
            rating: res.data.videoData.Episode3.note,
          });
        } else if (this.state.stateUrl == 4) {
          this.setState({
            rating: res.data.videoData.Episode4.note,
          });
        } else {
        }

        // console.log(res.data);
      })
      .catch((err) => {
        //alert("Token invalide");
        // console.log(err);
      });
  };

  handleChangeRetour = (e) => {
    this.setState({ etatPopupSuppression: !this.state.etatPopupSuppression });
  };

  handleAlert = (e) => {
    alert("Fonctionnalité en cours ");
  };

  handleChangeSupprimer = (event) => {
    axios
      .post(`https://serveur.thewhitecoats.com/users/deleteUserValidated`, {
        token: this.state.Token,
      })
      .then((res) => {
        alert("Vos informations ont bien été supprimer");
        localStorage.removeItem("email");
        localStorage.removeItem("Token");
        window.location.reload(false);
        //console.log(res);
      })
      .catch((err) => {
        //alert("Erreur !");
        //console.log(err);
      });
  };
  handleChangePrenom(event) {
    this.setState({ prenom: event.target.value });
  }
  handleChangeNom(event) {
    this.setState({ prenom: event.target.value });
  }
  handleChangeEmailP(event) {
    this.setState({ prenom: event.target.value });
  }
  handleChangeEmailS(event) {
    this.setState({ prenom: event.target.value });
  }
  handleChange = (centre) => {
    this.setState({ centre }, () =>
      console.log(`Option selected:`, this.state.centre.value)
    );
  };

  handleChangeSpecialite = (specialite) => {
    this.setState({ specialite }, () =>
      console.log(`Option selected:`, this.state.specialite.value)
    );
  };

  handleCopie = () => {
    navigator.clipboard.writeText(
      "Bonjour,\n \nJe vous recommande cette série de documentaires fictions immersifs sur l’état de l’art de la prise en charge des patients en oncologie thoracique, le format vidéo est très sympa !\n \nDécouvrir Whitecoats : thewhitecoats.com"
    );
    this.setState({ copie: 1 });

    setTimeout(() => {
      this.setState({ copie: 0 });
    }, "3000");
  };

  handleEpisodeSuivant = () => {
    var Token = localStorage.getItem("Token");

    if (this.state.stateUrl == 1) {
      localStorage.setItem("timeStampE1", this.state.timeStampE1);
      this.state.porcentage1 =
        (localStorage.getItem("timeStampE1") * 100) / 819;
      this.setState({ stateUrl: 2 });
      axios
        .post(`https://serveur.thewhitecoats.com/video/startVideo`, {
          video: "Episode2",
          token: Token,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          //alert("Erreur !");
          //console.log(err);
        });
    } else if (this.state.stateUrl == 2) {
      localStorage.setItem("timeStampE2", this.state.timeStampE2);

      this.state.porcentage2 =
        (localStorage.getItem("timeStampE2") * 100) / 663;
      this.setState({ stateUrl: 3 });
      axios
        .post(`https://serveur.thewhitecoats.com/video/startVideo`, {
          video: "Episode3",
          token: Token,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          //alert("Erreur !");
          //console.log(err);
        });
    } else if (this.state.stateUrl == 3) {
      localStorage.setItem("timeStampE3", this.state.timeStampE3);

      this.state.porcentage3 =
        (localStorage.getItem("timeStampE3") * 100) / 518;
      this.setState({ stateUrl: 4 });
      axios
        .post(`https://serveur.thewhitecoats.com/video/startVideo`, {
          video: "Episode4",
          token: Token,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          //alert("Erreur !");
          //console.log(err);
        });
    } else {
    }
  };

  render() {
    var templateSuivent = "";

    if (this.state.stateUrl == 1 && this.state.timeStampE1 > 807) {
      templateSuivent = (
        <div className="containerSuivent">
          <a>
            <img
              src="https://thewhitecoats.com/IMG/dev/episode-suivant.png"
              onClick={this.handleEpisodeSuivant}
              className="episodeSuivant"
            />
          </a>
        </div>
      );
    } else if (this.state.stateUrl == 2 && this.state.timeStampE2 > 648) {
      templateSuivent = (
        <div className="containerSuivent">
          <a>
            <img
              src="https://thewhitecoats.com/IMG/dev/episode-suivant.png"
              onClick={this.handleEpisodeSuivant}
              className="episodeSuivant"
            />
          </a>
        </div>
      );
    } else if (this.state.stateUrl == 3 && this.state.timeStampE3 > 500) {
      templateSuivent = (
        <div className="containerSuivent">
          <a>
            <img
              src="https://thewhitecoats.com/IMG/dev/episode-suivant.png"
              onClick={this.handleEpisodeSuivant}
              className="episodeSuivant"
            />
          </a>
        </div>
      );
    } else {
    }

    var templateCopie = "";

    if (this.state.copie == 1) {
      templateCopie = (
        <div className="containerCopie">
          Copie dans le presse-papiers réussie
        </div>
      );
    }

    var templatepartager = "";

    if (this.state.partager == 1) {
      templatepartager = (
        <div className="containerPartager">
          <div className="containerTitlePartager">
            <div className="titlePartager"> PARTAGER PAR EMAIL</div>
            <div className="closePartager" onClick={this.handleClosePartage}>
              X
            </div>
          </div>

          <div className="Partager">
            <a
              href="mailto:?&subject=Je t’invite à découvrir Whitecoats Oncologie Thoracique saison 1 !&cc=support@thewhitecoats.com&body=Bonjour,  %0A %0A
Je vous recommande cette série de documentaires fictions immersifs sur l’état de l’art de la prise en charge des patients en oncologie thoracique, le format vidéo est très sympa ! %0A %0A
Découvrir Whitecoats : thewhitecoats.com   %0A %0A Leur hotline est en copie si vous avez des questions pour vous inscrire."
              target="_blank"
            >
              <img src="https://thewhitecoats.com/IMG/dev/partager-confrere-internes.png" />
            </a>

            <div className="Rien">
              Si rien ne se passe en cliquant,{" "}
              <span className="copie" onClick={this.handleCopie}>
                {" "}
                copiez ici
              </span>{" "}
              les informations <br /> et envoyer-les directement par email.
            </div>
          </div>
        </div>
      );
    }

    if (this.state.partager2 == 1) {
      var templatepartager2 = (
        <div className="containerPartager2">
          <div className="containerTitlePartager2">
            <div className="titlePartager2"> PARTAGER PAR EMAIL</div>
            <div className="closePartager" onClick={this.handleClosePartage2}>
              X
            </div>
          </div>

          <div className="Partager">
            <a
              href="mailto:?&subject=Je t’invite à découvrir Whitecoats Oncologie Thoracique saison 1 !&cc=support@thewhitecoats.com&body=Bonjour,  %0A %0A
  Je vous recommande cette série de documentaires fictions immersifs sur l’état de l’art de la prise en charge des patients en oncologie thoracique, le format vidéo est très sympa ! %0A %0A
  Découvrir Whitecoats : thewhitecoats.com   %0A %0A Leur hotline est en copie si vous avez des questions pour vous inscrire."
              target="_blank"
              onClick={this.handleClosePartage2}
            >
              <img src="https://thewhitecoats.com/IMG/dev/partager-confrere-internes.png" />
            </a>

            <div className="Rien">
              Si rien ne se passe en cliquant,{" "}
              <span className="copie" onClick={this.handleCopie}>
                {" "}
                copiez ici
              </span>{" "}
              les informations <br /> et envoyer-les directement par email.
            </div>
          </div>
        </div>
      );
    }

    // noter evaluer

    var templateEvaluer = "";

    if (this.state.evaluer == 1) {
      templateEvaluer = (
        <div className="ContainerEvaluder">
          <div className="containerTitleEvaluer">
            <div className="titleEvaluer">
              {" "}
              Donner votre avis sur l'épisode en notant de 1 à 5 étoiles !
            </div>
            <div className="closeEvaluer" onClick={this.handleCloseEvaluer}>
              X
            </div>
          </div>
          <div className="Evaluer">
            <StarRatings
              rating={this.state.rating}
              starRatedColor="#ffcd3c"
              changeRating={this.changeRating}
              numberOfStars={5}
              name="rating"
              starSpacing="1px"
              starDimension="40px"
              starHoverColor="#ffcd3c"
            />
          </div>
        </div>
      );
    }

    // Page connecté
    var ConnectedPage = "";

    if (this.state.connected == false) {
      ConnectedPage = (
        <div>
          <div className="ContainerRegistre">
            <div className="annonce">
              <div className="textAnnonce">
                <p>
                  {" "}
                  Whitecoats est un média digital de formation médicale de
                  nouvelle génération destiné à tous les professionnels de
                  santé.
                </p>
                <p>
                  {" "}
                  Chez Whitecoats nous transformons l’apprentissage des bonnes
                  pratiques médicales en séries documentaires fictions
                  immersives. Dans chaque épisode, nos experts partagent leur
                  expertise afin d’approfondir les thématiques abordées.
                </p>
              </div>

              <div className="VideoAnnonce">
                <p className="TitreViddeo"> BANDE ANNONCE SAISON 1 </p>
                <div>
                  <ReactPlayer
                    // Disable download button
                    config={{
                      file: { attributes: { controlsList: "nodownload" } },
                    }}
                    // Disable right click
                    onContextMenu={(e) => e.preventDefault()}
                    url="https://thewhitecoats.com/IMG/Teaser%20-%20Utilisateur%20-%20H264%2010Mbps.mp4"
                    width={500}
                    height={281}
                    light="https://thewhitecoats.com/IMG/miniature-bande-annonce-play.png"
                    playsinline={true}
                    playIcon={<div></div>}
                    muted={false}
                    playing={true}
                    loop={true}
                    controls
                    className="videoAnnonceContent"
                  />
                </div>
              </div>
            </div>
            <div className="Registre">
              <ContainerRegister token={this.props.match.params.token} />
            </div>
          </div>
          <div className="Experts">
            <Experts />
          </div>
        </div>
      );
    } else {
      ConnectedPage = (
        <div className="containerConnect">
          {/*this.handleSocketAuthentificat*/}
          <Deconnection
            handleLangChangeInfoParent2={this.handleInfo2}
            handleLangChangeInfoParent={this.handleInfo}
          />
          <div className="titreDirecteur">
            Le Docteur Aurélie Swalduz vous présente la{" "}
            <strong>saison 1.</strong>
          </div>
          <div className="videoDirecteur">
            <ReactPlayer
              // Disable download button
              config={{ file: { attributes: { controlsList: "nodownload" } } }}
              // Disable right click
              onContextMenu={(e) => e.preventDefault()}
              url="https://thewhitecoats.com/IMG/Teaser%20-%20Utilisateur%20-%20H264%2010Mbps.mp4"
              width={300}
              height={177}
              light="https://thewhitecoats.com/IMG/Minia-Teaser.png"
              playing
              controls
              playIcon={<div></div>}
            />
          </div>

          <div className="containerVideowhite">
            <div className="containerE1">
              <div className="E1">
                <div className="containerE1Note">
                  <div className="titreE1"> ÉPISODE 1</div>

                  {/**
                   * <div className="vues">
                    <div className="nbVue">{this.state.nbVuesE1}</div>
                    <img
                      src="https://thewhitecoats.com/IMG/dev/oeil-pour-vues.png"
                      alt="Avatar"
                      class="imageVues"
                    />
                  </div>
                   *  <div className="containerEvaluer1">
                    <div className="moyenne">{this.state.evaluerE1}</div>
                    <div className="note">
                      <StarRatings
                        rating={this.state.evaluerE1}
                        starRatedColor="white"
                        numberOfStars={5}
                        name="rating"
                        starSpacing="1px"
                        starDimension="15px"
                        starHoverColor="#ffcd3c"
                      />
                    </div>
                    <div className="nbVote">({this.state.nbEvaluerE1})</div>
                  </div>
                   * 
                   */}
                </div>

                <div className="videoE1">
                  <img
                    src="https://thewhitecoats.com/IMG/miniature-episode-1.png"
                    alt="Avatar"
                    class="imageE1"
                    onClick={this.handleVideo}
                  />
                </div>
                <ProgressBar
                  completed={this.state.porcentage1}
                  className="wrapper"
                  barContainerClassName="containerBar"
                  labelClassName="label"
                  bgColor="white"
                  height="8px"
                />
                <div className="descriptionE1">
                  <strong> Synopsis</strong> L’équipe d’oncologie thoracique du
                  Dr FONDEVAL prend en charge Mme LOMBARDI, arrivée des urgences
                  avec de fortes douleurs...
                </div>
              </div>

              <div className="ApperçuE1">
                <div className="titreE1"> ÉPISODE 1</div>
                <div className="videoE1">
                  <ReactPlayer
                    url="https://thewhitecoats.com/IMG/Micro-sequences/Episode%201%20-%20Micro-S%C3%A9quences%20H264%203Mbps.mp4"
                    width="100%"
                    height={330}
                    playing={true}
                    playIcon={<div></div>}
                    loop={true}
                    playsinline={true}
                    muted={true}
                    onReady={(e) => {
                      const player = e.getInternalPlayer();
                      player.setAttribute("muted", "true");
                    }}
                    autoplay
                    onClick={this.handleVideo}
                  />
                </div>
                <ProgressBar
                  completed={this.state.porcentage1}
                  className="wrapperApperçu"
                  barContainerClassName="containerBar"
                  labelClassName="label"
                  bgColor="white"
                  height="8px"
                />
                <div className="descriptionE1">
                  <strong> Synopsis :</strong> <br />
                  L’équipe d’oncologie thoracique du Dr FONDEVAL prend en charge
                  Mme LOMBARDI, arrivée des urgences avec de fortes douleurs. Un
                  diagnostic de cancer bronchique non à petites cellules (CBNPC)
                  métastatique est établi. L’équipe médicale décide en RCP de
                  lancer une biologie moléculaire. Un point expert permet de
                  préciser le bon timing de la caractérisation moléculaire afin
                  de prescrire l’immunothérapie la mieux adaptée au profil de la
                  patiente. On découvre, par ailleurs, un nouveau patient : Mr
                  DEMARCHELIER, dont la biopsie révèle la nature cancéreuse des
                  cellules pulmonaires. Le Dr LORIOT lui prescrit un bilan
                  d’extension complet pour réaliser le staging de son cancer.
                </div>

                <div className="footerAperçu">
                  <div className="commentExpert">
                    <div className="titrecommentExpert">
                      <strong>Commentaire Expert par :</strong> <br />
                      Pr. Michaël DURUISSEAUX
                    </div>

                    <img
                      src="https://thewhitecoats.com/IMG/Experts_Videos/Michael%20DURUISSEAUX.png"
                      className="imagesExpertsApperçu"
                    />
                  </div>

                  <div className="PartagerRegarder">
                    <a>
                      <img
                        src="https://thewhitecoats.com/IMG/dev/regarder.png"
                        onClick={this.handleVideo}
                      />
                    </a>

                    <a className="partagerHover">
                      <img
                        src="https://thewhitecoats.com/IMG/dev/partager.png"
                        onClick={this.handleOpenPartage2}
                      />
                    </a>
                  </div>
                  {templatepartager2}
                </div>
              </div>
            </div>

            {/**Episode 2 */}

            <div className="containerE1">
              <div className="E1">
                <div className="containerE1Note">
                  <div className="titreE1"> ÉPISODE 2</div>

                  {/**
                   *  <div className="vues">
                    <div className="nbVue">{this.state.nbVuesE2}</div>
                    <img
                      src="https://thewhitecoats.com/IMG/dev/oeil-pour-vues.png"
                      alt="Avatar"
                      class="imageVues"
                    />
                  </div>
                   *  <div className="containerEvaluer1">
                    <div className="moyenne">{this.state.evaluerE2}</div>
                    <div className="note">
                      <StarRatings
                        rating={this.state.evaluerE2}
                        starRatedColor="white"
                        numberOfStars={5}
                        name="rating"
                        starSpacing="1px"
                        starDimension="15px"
                        starHoverColor="#ffcd3c"
                      />
                    </div>
                    <div className="nbVote">({this.state.nbEvaluerE2})</div>
                  </div>
                   */}
                </div>

                <div className="videoE1">
                  <img
                    src="https://thewhitecoats.com/IMG/miniature-episode-2.png"
                    alt="Avatar"
                    class="imageE1"
                    onClick={this.handleVideoE2}
                  />
                </div>
                <ProgressBar
                  completed={this.state.porcentage2}
                  className="wrapper"
                  barContainerClassName="containerBar"
                  labelClassName="label"
                  bgColor="white"
                  height="8px"
                />
                <div className="descriptionE1">
                  <strong> Synopsis</strong> : Quelques semaines plus tard, on
                  retrouve Mme LOMBARDI en consultation dans le service
                  d’oncologie médical...
                </div>
              </div>

              <div className="ApperçuE1">
                <div className="titreE1"> ÉPISODE 2</div>
                <div className="videoE1">
                  <ReactPlayer
                    url="https://thewhitecoats.com/IMG/Micro-sequences/Episode%202%20-%20Micro-S%C3%A9quences%20H264%203Mbps.mp4"
                    width="100%"
                    height={330}
                    playing={true}
                    playIcon={<div></div>}
                    loop={true}
                    playsinline={true}
                    volume={0.5}
                    muted={true}
                    onReady={(e) => {
                      const player = e.getInternalPlayer();
                      player.setAttribute("muted", "true");
                    }}
                    autoplay
                    onClick={this.handleVideoE2}
                  />
                </div>
                <ProgressBar
                  completed={this.state.porcentage2}
                  className="wrapperApperçu"
                  barContainerClassName="containerBar"
                  labelClassName="label"
                  bgColor="white"
                  height="8px"
                />
                <div className="descriptionE1">
                  <strong> Synopsis :</strong> <br />
                  Quelques semaines plus tard, on retrouve Mme LOMBARDI en
                  consultation dans le service d’oncologie médical. Elle
                  découvre que son CBNPC est muté EGFR ; ce qui va permettre sa
                  mise sous traitement ciblé. Un nouveau patient arrive dans le
                  service. Il est atteint d’un cancer bronchique à petites
                  cellules (CBPC) et arrive au bout de son cycle de
                  chimiothérapie. Le point expert discute de l’intérêt des
                  traitements complémentaires des CBPC, tels que l’irradiation
                  cérébrale prophylactique et la radiothérapie thoracique de
                  clôture. Il discute aussi du nombre de cures recommandées pour
                  la chimio et la chimio immunothérapie.
                </div>

                <div className="footerAperçu">
                  <div className="commentExpert">
                    <div className="titrecommentExpert">
                      <strong>Commentaire Expert par :</strong> <br />
                      Dr. Lionel FALCHERO
                    </div>
                    <img
                      src="https://thewhitecoats.com/IMG/Experts_Videos/Lionel%20FALCHERO.png"
                      className="imagesExpertsApperçu"
                    />
                  </div>

                  <div className="PartagerRegarder">
                    <a>
                      <img
                        src="https://thewhitecoats.com/IMG/dev/regarder.png"
                        onClick={this.handleVideoE2}
                      />
                    </a>

                    <a>
                      {" "}
                      <img
                        src="https://thewhitecoats.com/IMG/dev/partager.png"
                        onClick={this.handleOpenPartage2}
                      />
                    </a>
                  </div>
                  {templatepartager2}
                </div>
              </div>
            </div>

            {/**Episode 3 */}

            <div className="containerE1">
              <div className="E1">
                <div className="containerE1Note">
                  <div className="titreE1"> ÉPISODE 3</div>

                  {/**
                   *  <div className="vues">
                    <div className="nbVue">{this.state.nbVuesE3}</div>
                    <img
                      src="https://thewhitecoats.com/IMG/dev/oeil-pour-vues.png"
                      alt="Avatar"
                      class="imageVues"
                    />
                  </div>
                   *   <div className="containerEvaluer1">
                    <div className="moyenne">{this.state.evaluerE3}</div>
                    <div className="note">
                      <StarRatings
                        rating={this.state.evaluerE3}
                        starRatedColor="white"
                        numberOfStars={5}
                        name="rating"
                        starSpacing="1px"
                        starDimension="15px"
                        starHoverColor="#ffcd3c"
                      />
                    </div>
                    <div className="nbVote">({this.state.nbEvaluerE3})</div>
                  </div>
                   * 
                   */}
                </div>

                <div className="videoE1">
                  <img
                    src="https://thewhitecoats.com/IMG/miniature-episode-3.png"
                    alt="Avatar"
                    class="imageE1"
                    onClick={this.handleVideoE3}
                  />
                </div>
                <ProgressBar
                  completed={this.state.porcentage3}
                  className="wrapper"
                  barContainerClassName="containerBar"
                  labelClassName="label"
                  bgColor="white"
                  height="8px"
                />
                <div className="descriptionE1">
                  <strong> Synopsis</strong> : L’équipe se réunit en RCP et la
                  question se pose de savoir si on opère d'emblée Mr
                  DEMARCHELIER ...
                </div>
              </div>

              <div className="ApperçuE1">
                <div className="titreE1"> ÉPISODE 3</div>
                <div className="videoE1">
                  <ReactPlayer
                    url="https://thewhitecoats.com/IMG/Micro-sequences/Episode%203%20-%20Micro-S%C3%A9quences%20H264%203Mbps.mp4"
                    width="100%"
                    height={330}
                    playing={true}
                    playIcon={<div></div>}
                    loop={true}
                    playsinline={true}
                    volume={0.5}
                    muted={true}
                    onReady={(e) => {
                      const player = e.getInternalPlayer();
                      player.setAttribute("muted", "true");
                    }}
                    autoplay
                    onClick={this.handleVideoE3}
                  />
                </div>
                <ProgressBar
                  completed={this.state.porcentage3}
                  className="wrapperApperçu"
                  barContainerClassName="containerBar"
                  labelClassName="label"
                  bgColor="white"
                  height="8px"
                />
                <div className="descriptionE1">
                  <strong> Synopsis :</strong> <br />
                  L’équipe se réunit en RCP et la question se pose de savoir si
                  on opère d'emblée Mr DEMARCHELIER ou si on lui prescrit
                  d’abord un traitement néoadjuvant avant de l’opérer. Deux
                  experts discutent des modalités de réalisation des tests
                  moléculaires, telles que le « reflex testing » lors des stades
                  précoces opérables mais aussi l’intérêt de la vérification
                  histologique des adénopathies médiastinales via l’EBUS de
                  staging.
                </div>

                <div className="titrecommentExpert">
                  <strong>Commentaire Expert par :</strong> <br />
                </div>

                <div className="footerAperçu">
                  <div className="commentExpert">
                    <div className="titrecommentExpert">Dr. Agathe SEGUIN</div>
                    <img
                      src="https://thewhitecoats.com/IMG/Experts_Videos/Agathe%20SEGUIN.png"
                      className="imagesExpertsApperçu2"
                    />{" "}
                  </div>
                  <div className="commentExpert">
                    <div className="titrecommentExpert">
                      Pr. Sylvie LANTUEJOUL
                    </div>
                    <img
                      src="https://thewhitecoats.com/IMG/Experts_Videos/Sylvie%20LANTUEJOUL.png"
                      className="imagesExpertsApperçu2"
                    />{" "}
                  </div>

                  <div className="PartagerRegarder" style={{ marginTop: "9%" }}>
                    <a>
                      <img
                        src="https://thewhitecoats.com/IMG/dev/regarder.png"
                        onClick={this.handleVideoE3}
                      />
                    </a>

                    <a>
                      {" "}
                      <img
                        src="https://thewhitecoats.com/IMG/dev/partager.png"
                        onClick={this.handleOpenPartage2}
                      />
                    </a>
                  </div>
                  {templatepartager2}
                </div>
              </div>
            </div>

            {/**Episode 4 */}

            <div className="containerE1">
              <div className="E1">
                <div className="containerE1Note">
                  <div className="titreE1"> ÉPISODE 4</div>

                  {/**
                   *    
                   * 
                   * 
                   * 
                   *  <div className="vues">
                    <div className="nbVue">{this.state.nbVuesE4}</div>
                    <img
                      src="https://thewhitecoats.com/IMG/dev/oeil-pour-vues.png"
                      alt="Avatar"
                      class="imageVues"
                    />
                  </div><div className="containerEvaluer1">
                    <div className="moyenne">{this.state.evaluerE4}</div>
                    <div className="note">
                      <StarRatings
                        rating={this.state.evaluerE4}
                        starRatedColor="white"
                        numberOfStars={5}
                        name="rating"
                        starSpacing="1px"
                        starDimension="15px"
                        starHoverColor="#ffcd3c"
                      />
                    </div>
                    <div className="nbVote">({this.state.nbEvaluerE4})</div>
                  </div>
                   * 
                   */}
                </div>
                <div className="videoE1">
                  <img
                    src="https://thewhitecoats.com/IMG/miniature-episode-4.png"
                    alt="Avatar"
                    class="imageE1"
                    onClick={this.handleVideoE4}
                  />
                </div>
                <ProgressBar
                  completed={this.state.porcentage4}
                  className="wrapper"
                  barContainerClassName="containerBar"
                  labelClassName="label"
                  bgColor="white"
                  height="8px"
                />
                <div className="descriptionE1">
                  <strong> Synopsis</strong> : Le Dr LORIOT revoit Mr
                  DEMARCHELIER 10 jours après sa chirurgie et lui présente les
                  premiers résultats de sa chimio-immunothérapie...
                </div>
              </div>

              <div className="ApperçuE4">
                <div className="titreE1"> ÉPISODE 4</div>
                <div className="videoE1">
                  <ReactPlayer
                    url="https://thewhitecoats.com/IMG/Micro-sequences/Episode%204%20-%20Micro-S%C3%A9quences%20H264%203Mbps.mp4"
                    width="100%"
                    height={330}
                    playing={true}
                    playIcon={<div></div>}
                    loop={true}
                    playsinline={true}
                    volume={0.5}
                    muted={true}
                    onReady={(e) => {
                      const player = e.getInternalPlayer();
                      player.setAttribute("muted", "true");
                    }}
                    autoplay
                    onClick={this.handleVideoE4}
                  />
                </div>
                <ProgressBar
                  completed={this.state.porcentage4}
                  className="wrapperApperçu"
                  barContainerClassName="containerBar"
                  labelClassName="label"
                  bgColor="white"
                  height="8px"
                />
                <div className="descriptionE1">
                  <strong> Synopsis :</strong> <br />
                  Le Dr LORIOT revoit Mr DEMARCHELIER 10 jours après sa
                  chirurgie et lui présente les premiers résultats de sa
                  chimio-immunothérapie. C’est l’occasion pour l’experte
                  d’aborder les modalités de traitements des CBNPC localisés
                  après une réponse complète pathologique. Le Dr FONDEVAL
                  découvre en consultation un nouveau patient : Mr MORVAN, qui
                  vient de passer une médiastinoscopie, qui révèle des cellules
                  cancéreuses sur des ganglions… L’expert discute alors les
                  modalités de traitement des CBNPC de stade III selon leur
                  statut PDL1.
                </div>

                <div className="titrecommentExpert">
                  <strong>Commentaire Expert par :</strong> <br />
                </div>
                <div className="footerAperçu">
                  <div className="commentExpert">
                    <div className="titrecommentExpert">
                      Pr. Virginie WESTEEL{" "}
                    </div>
                    <img
                      src="https://thewhitecoats.com/IMG/Experts_Videos/Virginie%20WESTEEL.png"
                      className="imagesExpertsApperçu2"
                    />{" "}
                  </div>

                  <div className="commentExpert">
                    <div className="titrecommentExpert">
                      Dr. Charles RICORDEL
                    </div>
                    <img
                      src="https://thewhitecoats.com/IMG/Experts_Videos/Charles%20RICORDEL.png"
                      className="imagesExpertsApperçu2"
                    />{" "}
                  </div>

                  <div className="PartagerRegarder" style={{ marginTop: "9%" }}>
                    <a>
                      <img
                        src="https://thewhitecoats.com/IMG/dev/regarder.png"
                        onClick={this.handleVideoE4}
                      />
                    </a>

                    <a>
                      {" "}
                      <img
                        src="https://thewhitecoats.com/IMG/dev/partager.png"
                        onClick={this.handleOpenPartage2}
                      />
                    </a>
                  </div>
                  {templatepartager2}
                </div>
              </div>
            </div>
          </div>
          <div className="containerVideowhiteProduction">
            <div className="containerP">
              <div className="E1">
                <div className="titreE1"> ÉPISODE 5</div>
                <div className="videoE1">
                  <img
                    src="https://thewhitecoats.com/IMG/miniature-en-production.png"
                    alt="Avatar"
                    class="imageE1"
                  />
                </div>

                <div className="descriptionE1">
                  {/** <strong> Synopsis</strong> : Lorem ipsum dolor sit amet,
                  adipiscing elit, sed diam nonummy nibh..*/}
                </div>
              </div>
            </div>

            <div className="containerP">
              <div className="E1">
                <div className="titreE1"> ÉPISODE 6</div>
                <div className="videoE1">
                  <img
                    src="https://thewhitecoats.com/IMG/miniature-en-production.png"
                    alt="Avatar"
                    class="imageE1"
                  />
                </div>

                <div className="descriptionE1">
                  {/** <strong> Synopsis</strong> : Lorem ipsum dolor sit amet,
                  adipiscing elit, sed diam nonummy nibh..*/}
                </div>
              </div>
            </div>

            <div className="containerP">
              <div className="E1">
                <div className="titreE1"> ÉPISODE 7</div>
                <div className="videoE1">
                  <img
                    src="https://thewhitecoats.com/IMG/miniature-en-production.png"
                    alt="Avatar"
                    class="imageE1"
                  />
                </div>

                <div className="descriptionE1">
                  {/** <strong> Synopsis</strong> : Lorem ipsum dolor sit amet,
                  adipiscing elit, sed diam nonummy nibh..*/}
                </div>
              </div>
            </div>

            <div className="containerP">
              <div className="E1">
                <div className="titreE1"> ÉPISODE 8</div>
                <div className="videoE1">
                  <img
                    src="https://thewhitecoats.com/IMG/miniature-en-production.png"
                    alt="Avatar"
                    class="imageE1"
                  />
                </div>

                {/** <ProgressBar
                  completed={0}
                  className="wrapper2"
                  barContainerClassName="containerBar"
                  labelClassName="label"
                  bgColor="white"
                  height="8px"
                /> */}

                <div className="descriptionE1">
                  {/** <strong> Synopsis</strong> : Lorem ipsum dolor sit amet,
                  adipiscing elit, sed diam nonummy nibh..*/}
                </div>
              </div>
            </div>
          </div>
          <Comment />
        </div>
      );
    }

    var templatepageAllVideo = (
      <div className="ContainerWhite">
        <div className="Header">
          <img
            class="logo"
            src="https://thewhitecoats.com/IMG/Logo_WHITECOATS.png"
            alt="Logo WhiteCoast"
            width={278}
          />
        </div>

        {ConnectedPage}
        {templateCopie}

        <div>
          <div className="FooterExpert2">
            <div className="Mentions">
              {" "}
              <a href="https://thewhitecoats.com/CGU.html" target="_blank">
                {" "}
                Mentions légales
              </a>
            </div>{" "}
            <p className="TraitUnion">-</p>
            <div className="Mentions">
              {" "}
              <a
                href="https://thewhitecoats.com/Politique-de-confidentialite.html "
                target="_blank"
              >
                {" "}
                Politique de confidentialité
              </a>
            </div>
            {/**
           *  <p className="TraitUnion">-</p>
          <div className="Mentions">Gestion des cookies</div>
           * 
           */}
          </div>

          <div className="Whitecoats2023"> © Whitecoats 2024</div>
        </div>
      </div>
    );
    var template = "";

    var url = "";
    if (this.state.stateUrl == 1) {
      url =
        "https://thewhitecoats.com/IMG/Videos/Whitecoats%20-%20Episode%201%20-%20H264%2010Mbps.mp4";
    } else if (this.state.stateUrl == 2) {
      url =
        "https://thewhitecoats.com/IMG/Videos/Whitecoats%20-%20Episode%202%20-%20H264%2010Mbps.mp4";
    } else if (this.state.stateUrl == 3) {
      url =
        "https://thewhitecoats.com/IMG/Videos/Whitecoats%20-%20Episode%203%20-%20H264%2010Mbps.mp4";
    } else if (this.state.stateUrl == 4) {
      url =
        "https://thewhitecoats.com/IMG/Videos/Whitecoats%20-%20Episode%204%20-%20H264%2010Mbps.mp4";
    }

    //component video à déplacer
    var templateVideo = (
      <div className="pageVideo" onMouseMove={this.handleMousse}>
        <div className="ContainerVideo">
          <ReactPlayer
            // Disable download button
            config={{
              file: { attributes: { controlsList: "nodownload" } },
            }}
            // Disable right click
            ref={this.ref}
            onContextMenu={(e) => e.preventDefault()}
            url={url}
            playing
            playIcon={<div></div>}
            className="react-player"
            width="100%"
            height="100%"
            controls={true}
            onSeek={this.onSeek}
            onStart={this.onStart}
            onProgress={this.onProgress}
          />

          <div onClick={this.handleFullscreen} className="vide">
            {" "}
          </div>
        </div>

        <img
          src="https://thewhitecoats.com/IMG/dev/retour.png"
          className={this.state.mousse ? "retourBlock" : "retour"}
          onClick={this.handleRetour}
        />
        <a className={this.state.mousse ? "iconWhite" : "iconWhiteBlock"}>
          <img src="https://thewhitecoats.com/IMG/dev/whitecoats-icon.png" />
        </a>

        <div className={this.state.mousse ? "avisPartageBlock" : "avisPartage"}>
          <img
            src="https://thewhitecoats.com/IMG/dev/%c3%89VALUER-blanc.png"
            onClick={this.handleOpenEvaluer}
          />

          <img
            src="https://thewhitecoats.com/IMG/dev/partager-en-pause.png"
            onClick={this.handleOpenPartage}
          />
        </div>

        <div
          className="avisPartagetrans"
          className={
            this.state.mousse ? "avisPartagetrans" : "avisPartagetransBlock"
          }
        >
          <img src="https://thewhitecoats.com/IMG/dev/%c3%89VALUER-transparent.png" />

          <img src="https://thewhitecoats.com/IMG/dev/PARTAGER-transparent.png" />
        </div>
        {templatepartager}
        {templateEvaluer}
        {templateCopie}
        {templateSuivent}
      </div>
    );

    var TempateSuppression = "";
    if (this.state.etatPopupSuppression == true) {
      TempateSuppression = (
        <div className="containerLayer">
          <div className="containerRGPD">
            <div className="poprgpd">
              Êtes-vous sûr de vouloir supprimer votre compte ? *
            </div>

            <div className="btnSupprimer2" onClick={this.handleChangeSupprimer}>
              Oui, je supprime mon compte
            </div>
            <div className="btnSupprimer2" onClick={this.handleChangeRetour}>
              Non, je retourne en arrière
            </div>
            <div className="FooterDelete">
              {" "}
              *Cette action entraînera la suppression intégrale de toutes vos
              informations mais vous gardez la possibilité de venir créer
              spontanément un nouveau compte à l’avenir
            </div>
          </div>
        </div>
      );
    }

    var templateInfo = (
      <div className="ContainerWhite">
        <div className="Header">
          <img
            class="logo"
            src="https://thewhitecoats.com/IMG/Logo_WHITECOATS.png"
            alt="Logo WhiteCoast"
            width={278}
          />
        </div>

        <Deconnection
          handleLangChangeInfoParent={this.handleInfo}
          name={this.state.page}
          handleLangChangeInfoParent2={this.handleInfo2}
        />

        <div className="containerInfo">
          <div className="TitreInfo"> MES INFORMATIONS</div>
        </div>

        <div className="containerInfo2">
          <div
            style={{
              width: "120%",
              paddingLeft: 10,
              display: "inline-block",
            }}
          >
            Prénom * :
            <input
              type="text"
              className="NomPrenomInfo"
              style={{ width: "77.5%" }}
              name=""
              required
              value={this.state.prenom}
              onChange={this.handleChangePrenom}
            />
          </div>

          <div
            style={{
              width: "120%",
              paddingLeft: 10,
              display: "inline-block",
            }}
          >
            Nom * :
            <input
              type="text"
              className="NomPrenomInfo"
              name="Nom"
              required
              style={{ width: "79.3%" }}
              value={this.state.nom}
              onChange={this.handleChangeNom}
            />
          </div>

          <div
            style={{
              width: "120%",
              paddingLeft: 10,
              display: "inline-block",
            }}
          >
            Email principal * :
            <input
              type="email"
              className="MailPrincipale MailSecondaire2"
              name="email"
              required
              style={{ width: "74.3%" }}
              value={this.state.emailP}
              onChange={this.handleChangeEmailP}
            />
          </div>
          <div
            style={{
              width: "120%",
              paddingLeft: 10,
              display: "inline-block",
            }}
          >
            Email seccondaire :
            <input
              type="email"
              className="MailSecondaire MailSecondaire2"
              name="email"
              style={{ width: "73%" }}
              value={this.state.emailS}
              onChange={this.handleChangeEmailS}
            />
          </div>
          <div
            style={{
              width: "110%",
              paddingLeft: 10,
              display: "flex",
              marginTop: 25,
            }}
          >
            <span className="SelectNameSpecialite"> Spécialité * : </span>

            <Select
              options={this.state.specialites}
              value={this.state.specialite}
              onChange={this.handleChangeSpecialite}
              placeholder="Taper les première lettres"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  backgroundColor: "#2a414a",
                  color: "white",
                }),
                placeholder: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: "#2a414a",
                  color: "white",
                  backgroundColor: "#2a414a",
                }),

                SingleValue: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "white !important",
                }),
              }}
            />
          </div>
          <div
            style={{
              width: "113%",
              paddingLeft: 10,
              display: "flex",
              marginTop: 25,
            }}
          >
            <span className="SelectNameCentre2">Centre * : </span>

            <Select
              options={this.state.centers}
              value={centre}
              onChange={this.handleChange}
              placeholder="Taper les première lettres"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  backgroundColor: "#2a414a",
                  color: "white",
                }),
                placeholder: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: "#2a414a",
                  color: "white",
                  backgroundColor: "#2a414a",
                }),

                SingleValue: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "white !important",
                }),
              }}
            />
          </div>

          <div className="btnSupprimer" onClick={this.handleChangeRetour}>
            {" "}
            Je supprime mon compte
          </div>
        </div>
        {TempateSuppression}
        <a className="fermer">
          <img
            src="https://thewhitecoats.com/IMG/dev/fermer.png"
            onClick={this.handleRetour}
            className="fermerIMG"
          />
        </a>
        <div
          className="FooterExpert"
          style={{
            marginTop: "150px",
          }}
        >
          <div className="Mentions">
            {" "}
            <a href="https://thewhitecoats.com/CGU.html" target="_blank">
              {" "}
              Mentions légales
            </a>
          </div>{" "}
          <p className="TraitUnion">-</p>
          <div className="Mentions">
            {" "}
            <a
              href="https://thewhitecoats.com/Politique-de-confidentialite.html "
              target="_blank"
            >
              {" "}
              Politique de confidentialité
            </a>
          </div>
          {/**
           *  <p className="TraitUnion">-</p>
          <div className="Mentions">Gestion des cookies</div>
           * 
           */}
        </div>
        <div className="Whitecoats2023"> © Whitecoats 2024</div>
      </div>
    );
    const { centre } = this.state;
    const { specialite } = this.state;

    if (this.state.page == 1) {
      template = templateVideo;
    } else if (this.state.page == 2) {
      template = templateInfo;
    } else {
      template = templatepageAllVideo;
    }

    return <div>{template}</div>;
  }
}

export default white;
