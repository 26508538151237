import React, { Component } from "react";
import "./ContainerRegister.css";

import Login from "./Container/Login";
import Register from "./Container/Register";

class ContainerRegister extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      page: "Register",
      infoProfile: 0,
    };

    this.handleChangeToRegister = this.handleChangeToRegister.bind(this);
    this.handleChangeToLogin = this.handleChangeToLogin.bind(this);
  }

  handleChangeToRegister() {
    this.setState({ page: "Register" });
  }

  handleChangeToLogin() {
    this.setState({ page: "Login" });
  }

  componentWillMount() {}
  componentDidMount() {
    console.log(this.props.token);

    if (this.props.token != undefined) {
      this.setState({ infoProfile: 1 });
    }
  }

  render() {
    var myPage2 = "";

    if (this.state.page === "Register") {
      myPage2 = (
        <div>
          <ul className="UlClass">
            <li
              className={
                this.state.page === "Register"
                  ? "btnActive LIclassesRegister"
                  : "LIclassesRegister"
              }
              onClick={this.handleChangeToRegister}
            >
              <a>CRÉER VOTRE COMPTE</a>
            </li>
            <li
              className={
                this.state.page === "Login"
                  ? "btnActive LIclassesLogin"
                  : "LIclassesLogin"
              }
              onClick={this.handleChangeToLogin}
            >
              <a>SE CONNECTER</a>
            </li>
          </ul>
          <div className="jours3">
            <Register token={this.props.token} />
          </div>
        </div>
      );
    } else {
      myPage2 = (
        <div>
          <ul className="UlClass">
            <li
              className={
                this.state.page === "Register"
                  ? "btnActive LIclassesRegister"
                  : "LIclassesRegister"
              }
              onClick={this.handleChangeToRegister}
            >
              <a>CRÉER VOTRE COMPTE</a>
            </li>
            <li
              className={
                this.state.page === "Login"
                  ? "btnActive LIclassesLogin"
                  : "LIclassesLogin"
              }
              onClick={this.handleChangeToLogin}
            >
              <a>SE CONNECTER</a>
            </li>
          </ul>
          <div className="jours3">
            <Login />
          </div>
        </div>
      );
    }

    var tmpInformation = "";

    if (this.state.infoProfile == 1) {
      tmpInformation = (
        <div className="VerificationProfil">
          Veuillez vérifier les informations de votre profil et choisir un mot
          de passe.
        </div>
      );
    }

    return (
      <div className="ContainerRegister">
        {tmpInformation}
        {myPage2}
      </div>
    );
  }
}

export default ContainerRegister;
