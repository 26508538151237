import React, { Component } from "react";
import "./Video.css";
import ReactPlayer from "react-player";

class Video extends Component {
  constructor(props) {
    super(props);
    this.state = {
      etatDecconnection: false,
    };

    this.handleVideo = this.handleVideo.bind(this);
  }
  componentWillMount() {}
  componentDidMount() {}

  handleVideo(event) {
    this.setState({ etatDecconnection: true });
  }

  render() {
    return (
      <div>
        <div className="ContainerVideo">
          <ReactPlayer
            url="https://thewhitecoats.com/IMG/Episode1.mp4"
            playing
            className="react-player"
            width="100%"
            height="98%"
            playsinline={true}
            muted={true}
            playing={true}
            loop={true}
            controls={true}
          />
        </div>
        <img
          src="https://thewhitecoats.com/IMG/dev/retour.png"
          className="retour"
        />
        <img
          src="https://thewhitecoats.com/IMG/dev/partager.png"
          className="partager"
        />
      </div>
    );
  }
}

export default Video;
