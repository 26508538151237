import React, { Component } from "react";
import "./Register.css";
import { Alert } from "react-bootstrap";
import axios from "axios";
import SelectSearch from "react-select-search";
import "react-select-search/style.css";
import Select, { StylesConfig } from "react-select";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prenom: "",
      nom: "",
      emailP: "",
      emailS: "",
      specialite: "",
      centre: "",
      password: "",
      rgpd: false,
      sended: false,
      valid: false,
      tmpAlerte: 0,
      tmpAlerteEmail: 0,
      centers: [],
      specialites: [],
      etatCasscade: 0,
      departements: [],
      departement: "",
      countries: [],
      pays: "",
      Precision: "",
      Laboratoire: "",
      ville: "",
      popup: false,
      tokenPassword: "",
      tmplateAlertePassword: 0,
      tmplateAlerteUserExiste: 0,
      statuts: [],
      statut: "",
      visible: true,
      specialiteAll: "",
      specialitesALl: [],
      PrecisionCentre: "",
      valide: false,
      currentObject: [
        {
          value: "Industrie – annonceur",
          label: "Industrie – annonceur",
        },
      ],

      currentObjectHospitalier: {
        value: "Taper les première lettres",
        label: "",
      },

      currentObjectCenter: {
        value: "Taper les première lettres",
        label: "",
      },

      currentObjectSpecialite: {
        value: "Taper les première lettres",
        label: "",
      },

      info: 0,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangePrenom = this.handleChangePrenom.bind(this);
    this.handleChangeNom = this.handleChangeNom.bind(this);
    this.handleChangeEmailP = this.handleChangeEmailP.bind(this);
    this.handleChangeEmailS = this.handleChangeEmailS.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleChangeRGPD = this.handleChangeRGPD.bind(this);
    this.handleChangeAlerte = this.handleChangeAlerte.bind(this);
    this.handleChangeAlerteEmail = this.handleChangeAlerteEmail.bind(this);
    this.handleChangeSupprimer = this.handleChangeSupprimer.bind(this);
    this.handleChangePrecision = this.handleChangePrecision.bind(this);
    this.handleChangePrecisionCentre = this.handleChangePrecisionCentre.bind(
      this
    );
    this.handleChangeLaboratoire = this.handleChangeLaboratoire.bind(this);
    this.handleChangeVille = this.handleChangeVille.bind(this);

    this.handleSubmitPassword = this.handleSubmitPassword.bind(this);
  }

  componentWillMount() {}
  componentDidMount() {
    //console.log(this.props.token);

    setTimeout(() => {
      if (this.props.token && this.props.token.startsWith("psw=")) {
        this.setState({ tokenPassword: this.props.token.substr(4) });
      }

      if (this.props.token) {
        axios
          .post(`https://serveur.thewhitecoats.com/users/fetchUserByEmail`, {
            email: this.props.token,
          })
          .then((res) => {
            console.log("res.data");

            console.log(res.data.data);
            this.setState({
              prenom: res.data.data.prenom,
              nom: res.data.data.nom,
              emailP: res.data.data.email,
              emailS: res.data.data.email_sec ?? "",
              currentObjectSpecialite: {
                value: res.data.data.specialite,
                label: res.data.data.specialite,
              },
              currentObjectCenter: {
                value: res.data.data.centre,
                label: res.data.data.centre,
              },
              statut: res.data.data.status ?? "",
              specialite: res.data.data.specialite ?? "",
              centre: res.data.data.centre ?? "",
              Laboratoire: res.data.data.laboratoire ?? "",
              currentObjectHospitalier: {
                value: res.data.data.status,
                label: res.data.data.status,
              },
              valide: res.data.data.valide,
            });
          })
          .catch((err) => {
            //alert("Token invalide");
            console.log(err);
          });
      }

      setTimeout(() => {
        /* if (this.props.token && this.state.emailP !== "") {
          console.log("*****************");
          console.log(this.state.emailP);

          axios
            .post(`https://serveur.thewhitecoats.com/auth/rapidRegister`, {
              email: this.state.emailP,
            })
            .then((res) => {
              console.log(res.data.msg);

              if (res.data.msg == "success") {
                localStorage.setItem("Token", this.props.token);
                localStorage.setItem("email", this.state.emailP);
                window.location.href =
                  "https://thewhitecoats.com/#/" + this.state.emailP;
              }
            });
        }*/

        console.log("*****************");
        console.log(this.state.valide);
        if (this.props.token && this.state.valide == true) {
          localStorage.setItem("Token", this.props.token);
          localStorage.setItem("email", this.state.emailP);

          window.location.href =
            "https://thewhitecoats.com/#/" + this.state.emailP;
        }
      }, 400);

      //FetchCentre

      axios
        .get(`https://serveur.thewhitecoats.com/users/fetchCenter`, {})
        .then((res) => {
          const options = res.data.data.map((data, index) => {
            return {
              label: data.nom,
              value: data.nom,
              key: index,
            };
          });
          this.setState({
            centers: options,
          });

          this.state.centers.push(
            {
              label: "........................................................",
              value: "........................................................",
              key: 151,
            },

            {
              label: "Autre",
              value: "Autre",
              key: 150,
            }
          );
        })

        .catch((err) => {
          //alert("Token invalide");
          console.log(err);
        });

      //FetchCentre fetchSpe

      axios
        .get(`https://serveur.thewhitecoats.com/users/fetchSpe`, {})
        .then((res) => {
          const options2 = res.data.data.map((data, index) => {
            return {
              label: data.spe,
              value: data.spe,
              key: index,
            };
          });
          this.setState({
            specialites: options2,
          });
          // console.log(res.data.data);
          // console.log(this.state.specialites);
          this.state.specialites.push(
            {
              label: "........................................................",
              value: "........................................................",
              key: 75,
            },
            {
              label: "Autre",
              value: "Autre",
              key: 74,
            }
          );
        })

        .catch((err) => {
          //alert("Token invalide");
          console.log(err);
        });

      //FetchCentre fetchSpeALL

      axios
        .get(`https://serveur.thewhitecoats.com/users/fetchSpeAll`, {})
        .then((res) => {
          const options6 = res.data.data.map((data, index) => {
            return {
              label: data.spe,
              value: data.spe,
              key: index,
            };
          });
          this.setState({
            specialitesAll: options6,
          });
        })

        .catch((err) => {
          //alert("Token invalide");
          console.log(err);
        });

      const arryDep = [
        {
          code_departement: "1",
          nom_departement: "Ain",
        },
        {
          code_departement: "2",
          nom_departement: "Aisne",
        },
        {
          code_departement: "3",
          nom_departement: "Allier",
        },
        {
          code_departement: "4",
          nom_departement: "Alpes-de-Haute-Provence",
        },
        {
          code_departement: "5",
          nom_departement: "Hautes-Alpes",
        },
        {
          code_departement: "6",
          nom_departement: "Alpes-Maritimes",
        },
        {
          code_departement: "7",
          nom_departement: "Ardèche",
        },
        {
          code_departement: "8",
          nom_departement: "Ardennes",
        },
        {
          code_departement: "9",
          nom_departement: "Ariège",
        },
        {
          code_departement: "10",
          nom_departement: "Aube",
        },
        {
          code_departement: "11",
          nom_departement: "Aude",
        },
        {
          code_departement: "12",
          nom_departement: "Aveyron",
        },
        {
          code_departement: "13",
          nom_departement: "Bouches-du-Rhône",
        },
        {
          code_departement: "14",
          nom_departement: "Calvados",
        },
        {
          code_departement: "15",
          nom_departement: "Cantal",
        },
        {
          code_departement: "16",
          nom_departement: "Charente",
        },
        {
          code_departement: "17",
          nom_departement: "Charente-Maritime",
        },
        {
          code_departement: "18",
          nom_departement: "Cher",
        },
        {
          code_departement: "19",
          nom_departement: "Corrèze",
        },
        {
          code_departement: "21",
          nom_departement: "Côte-d'Or",
        },
        {
          code_departement: "22",
          nom_departement: "Côtes-d'Armor",
        },
        {
          code_departement: "23",
          nom_departement: "Creuse",
        },
        {
          code_departement: "24",
          nom_departement: "Dordogne",
        },
        {
          code_departement: "25",
          nom_departement: "Doubs",
        },
        {
          code_departement: "26",
          nom_departement: "Drôme",
        },
        {
          code_departement: "27",
          nom_departement: "Eure",
        },
        {
          code_departement: "28",
          nom_departement: "Eure-et-Loir",
        },
        {
          code_departement: "29",
          nom_departement: "Finistère",
        },
        {
          code_departement: "2A",
          nom_departement: "Corse-du-Sud",
        },
        {
          code_departement: "2B",
          nom_departement: "Haute-Corse",
        },
        {
          code_departement: "30",
          nom_departement: "Gard",
        },
        {
          code_departement: "31",
          nom_departement: "Haute-Garonne",
        },
        {
          code_departement: "32",
          nom_departement: "Gers",
        },
        {
          code_departement: "33",
          nom_departement: "Gironde",
        },
        {
          code_departement: "34",
          nom_departement: "Hérault",
        },
        {
          code_departement: "35",
          nom_departement: "Ille-et-Vilaine",
        },
        {
          code_departement: "36",
          nom_departement: "Indre",
        },
        {
          code_departement: "37",
          nom_departement: "Indre-et-Loire",
        },
        {
          code_departement: "38",
          nom_departement: "Isère",
        },
        {
          code_departement: "39",
          nom_departement: "Jura",
        },
        {
          code_departement: "40",
          nom_departement: "Landes",
        },
        {
          code_departement: "41",
          nom_departement: "Loir-et-Cher",
        },
        {
          code_departement: "42",
          nom_departement: "Loire",
        },
        {
          code_departement: "43",
          nom_departement: "Haute-Loire",
        },
        {
          code_departement: "44",
          nom_departement: "Loire-Atlantique",
        },
        {
          code_departement: "45",
          nom_departement: "Loiret",
        },
        {
          code_departement: "46",
          nom_departement: "Lot",
        },
        {
          code_departement: "47",
          nom_departement: "Lot-et-Garonne",
        },
        {
          code_departement: "48",
          nom_departement: "Lozère",
        },
        {
          code_departement: "49",
          nom_departement: "Maine-et-Loire",
        },
        {
          code_departement: "50",
          nom_departement: "Manche",
        },
        {
          code_departement: "51",
          nom_departement: "Marne",
        },
        {
          code_departement: "52",
          nom_departement: "Haute-Marne",
        },
        {
          code_departement: "53",
          nom_departement: "Mayenne",
        },
        {
          code_departement: "54",
          nom_departement: "Meurthe-et-Moselle",
        },
        {
          code_departement: "55",
          nom_departement: "Meuse",
        },
        {
          code_departement: "56",
          nom_departement: "Morbihan",
        },
        {
          code_departement: "57",
          nom_departement: "Moselle",
        },
        {
          code_departement: "58",
          nom_departement: "Nièvre",
        },
        {
          code_departement: "59",
          nom_departement: "Nord",
        },
        {
          code_departement: "60",
          nom_departement: "Oise",
        },
        {
          code_departement: "61",
          nom_departement: "Orne",
        },
        {
          code_departement: "62",
          nom_departement: "Pas-de-Calais",
        },
        {
          code_departement: "63",
          nom_departement: "Puy-de-Dôme",
        },
        {
          code_departement: "64",
          nom_departement: "Pyrénées-Atlantiques",
        },
        {
          code_departement: "65",
          nom_departement: "Hautes-Pyrénées",
        },
        {
          code_departement: "66",
          nom_departement: "Pyrénées-Orientales",
        },
        {
          code_departement: "67",
          nom_departement: "Bas-Rhin",
        },
        {
          code_departement: "68",
          nom_departement: "Haut-Rhin",
        },
        {
          code_departement: "69",
          nom_departement: "Rhône",
        },
        {
          code_departement: "70",
          nom_departement: "Haute-Saône",
        },
        {
          code_departement: "71",
          nom_departement: "Saône-et-Loire",
        },
        {
          code_departement: "72",
          nom_departement: "Sarthe",
        },
        {
          code_departement: "73",
          nom_departement: "Savoie",
        },
        {
          code_departement: "74",
          nom_departement: "Haute-Savoie",
        },
        {
          code_departement: "75",
          nom_departement: "Paris",
        },
        {
          code_departement: "76",
          nom_departement: "Seine-Maritime",
        },
        {
          code_departement: "77",
          nom_departement: "Seine-et-Marne",
        },
        {
          code_departement: "78",
          nom_departement: "Yvelines",
        },
        {
          code_departement: "79",
          nom_departement: "Deux-Sèvres",
        },
        {
          code_departement: "80",
          nom_departement: "Somme",
        },
        {
          code_departement: "81",
          nom_departement: "Tarn",
        },
        {
          code_departement: "82",
          nom_departement: "Tarn-et-Garonne",
        },
        {
          code_departement: "83",
          nom_departement: "Var",
        },
        {
          code_departement: "84",
          nom_departement: "Vaucluse",
        },
        {
          code_departement: "85",
          nom_departement: "Vendée",
        },
        {
          code_departement: "86",
          nom_departement: "Vienne",
        },
        {
          code_departement: "87",
          nom_departement: "Haute-Vienne",
        },
        {
          code_departement: "88",
          nom_departement: "Vosges",
        },
        {
          code_departement: "89",
          nom_departement: "Yonne",
        },
        {
          code_departement: "90",
          nom_departement: "Territoire de Belfort",
        },
        {
          code_departement: "91",
          nom_departement: "Essonne",
        },
        {
          code_departement: "92",
          nom_departement: "Hauts-de-Seine",
        },
        {
          code_departement: "93",
          nom_departement: "Seine-Saint-Denis",
        },
        {
          code_departement: "94",
          nom_departement: "Val-de-Marne",
        },
        {
          code_departement: "95",
          nom_departement: "Val-d'Oise",
        },
        {
          code_departement: "971",
          nom_departement: "Guadeloupe",
        },
        {
          code_departement: "972",
          nom_departement: "Martinique",
        },
        {
          code_departement: "973",
          nom_departement: "Guyane",
        },
        {
          code_departement: "974",
          nom_departement: "La Réunion",
        },
        {
          code_departement: "976",
          nom_departement: "Mayotte",
        },
      ];

      const options3 = arryDep.map((data, index) => {
        return {
          label: data.nom_departement,
          value: data.nom_departement,
          key: index,
        };
      });
      this.setState({
        departements: options3,
      });

      const arrycountries = [
        {
          iso3: "AFG",
          name_fr: "Afghanistan",
        },
        {
          iso3: "ZAF",
          name_fr: "Afrique du Sud",
        },
        {
          iso3: "ALB",
          name_fr: "Albanie",
        },
        {
          iso3: "DZA",
          name_fr: "Algérie",
        },
        {
          iso3: "DEU",
          name_fr: "Allemagne",
        },
        {
          iso3: "AND",
          name_fr: "Andorre",
        },
        {
          iso3: "AGO",
          name_fr: "Angola",
        },
        {
          iso3: "ATG",
          name_fr: "Antigua-et-Barbuda",
        },
        {
          iso3: "SAU",
          name_fr: "Arabie Saoudite",
        },
        {
          iso3: "ARG",
          name_fr: "Argentine",
        },
        {
          iso3: "ARM",
          name_fr: "Arménie",
        },
        {
          iso3: "ABW",
          name_fr: "Aruba",
        },
        {
          iso3: "AUS",
          name_fr: "Australie",
        },
        {
          iso3: "AUT",
          name_fr: "Autriche",
        },
        {
          iso3: "AZE",
          name_fr: "Azerbaïdjan",
        },
        {
          iso3: "BHS",
          name_fr: "Bahamas",
        },
        {
          iso3: "BHR",
          name_fr: "Bahreïn",
        },
        {
          iso3: "BGD",
          name_fr: "Bangladesh",
        },
        {
          iso3: "BRB",
          name_fr: "Barbade",
        },
        {
          iso3: "BEL",
          name_fr: "Belgique",
        },
        {
          iso3: "BLZ",
          name_fr: "Belize",
        },
        {
          iso3: "BEN",
          name_fr: "Bénin",
        },
        {
          iso3: "BMU",
          name_fr: "Bermudes",
        },
        {
          iso3: "BTN",
          name_fr: "Bhoutan",
        },
        {
          iso3: "BLR",
          name_fr: "Biélorussie",
        },
        {
          iso3: "MMR",
          name_fr: "Birmanie",
        },
        {
          iso3: "BOL",
          name_fr: "Bolivie",
        },
        {
          iso3: "BIH",
          name_fr: "Bosnie-Herzégovine",
        },
        {
          iso3: "BWA",
          name_fr: "Botswana",
        },
        {
          iso3: "BRA",
          name_fr: "Brésil",
        },
        {
          iso3: "BRN",
          name_fr: "Brunei",
        },
        {
          iso3: "BGR",
          name_fr: "Bulgarie",
        },
        {
          iso3: "BFA",
          name_fr: "Burkina Faso",
        },
        {
          iso3: "BDI",
          name_fr: "Burundi",
        },
        {
          iso3: "KHM",
          name_fr: "Cambodge",
        },
        {
          iso3: "CMR",
          name_fr: "Cameroun",
        },
        {
          iso3: "CAN",
          name_fr: "Canada",
        },
        {
          iso3: "CPV",
          name_fr: "Cap Vert",
        },
        {
          iso3: "CHL",
          name_fr: "Chili",
        },
        {
          iso3: "CHN",
          name_fr: "Chine",
        },
        {
          iso3: "CYP",
          name_fr: "Chypre",
        },
        {
          iso3: "COL",
          name_fr: "Colombie",
        },
        {
          iso3: "COM",
          name_fr: "Comores",
        },
        {
          iso3: "COG",
          name_fr: "Congo",
        },
        {
          iso3: "COD",
          name_fr: "Congo (Rép. dém.)",
        },
        {
          iso3: "PRK",
          name_fr: "Corée du Nord",
        },
        {
          iso3: "KOR",
          name_fr: "Corée du Sud",
        },
        {
          iso3: "CRI",
          name_fr: "Costa Rica",
        },
        {
          iso3: "CIV",
          name_fr: "Côte dIvoire",
        },
        {
          iso3: "HRV",
          name_fr: "Croatie",
        },
        {
          iso3: "CUB",
          name_fr: "Cuba",
        },
        {
          iso3: "CUW",
          name_fr: "Curaçao",
        },
        {
          iso3: "DNK",
          name_fr: "Danemark",
        },
        {
          iso3: "DJI",
          name_fr: "Djibouti",
        },
        {
          iso3: "DMA",
          name_fr: "Dominique",
        },
        {
          iso3: "EGY",
          name_fr: "Egypte",
        },
        {
          iso3: "ARE",
          name_fr: "Emirats arabes unis",
        },
        {
          iso3: "ECU",
          name_fr: "Equateur",
        },
        {
          iso3: "ERI",
          name_fr: "Erythrée",
        },
        {
          iso3: "ESP",
          name_fr: "Espagne",
        },
        {
          iso3: "EST",
          name_fr: "Estonie",
        },
        {
          iso3: "SWZ",
          name_fr: "Eswatini",
        },
        {
          iso3: "USA",
          name_fr: "Etats-Unis",
        },
        {
          iso3: "ETH",
          name_fr: "Ethiopie",
        },
        {
          iso3: "FJI",
          name_fr: "Fidji",
        },
        {
          iso3: "FIN",
          name_fr: "Finlande",
        },
        {
          iso3: "FRA",
          name_fr: "France",
        },
        {
          iso3: "GAB",
          name_fr: "Gabon",
        },
        {
          iso3: "GMB",
          name_fr: "Gambie",
        },
        {
          iso3: "GEO",
          name_fr: "Géorgie",
        },
        {
          iso3: "GHA",
          name_fr: "Ghana",
        },
        {
          iso3: "GIB",
          name_fr: "Gibraltar",
        },
        {
          iso3: "GRC",
          name_fr: "Grèce",
        },
        {
          iso3: "GRD",
          name_fr: "Grenade",
        },
        {
          iso3: "GRL",
          name_fr: "Groenland",
        },
        {
          iso3: "GUM",
          name_fr: "Guam",
        },
        {
          iso3: "GTM",
          name_fr: "Guatemala",
        },
        {
          iso3: "GIN",
          name_fr: "Guinée",
        },
        {
          iso3: "GNB",
          name_fr: "Guinée-Bissau",
        },
        {
          iso3: "GNQ",
          name_fr: "Guinée-Équatoriale",
        },
        {
          iso3: "GUY",
          name_fr: "Guyana",
        },
        {
          iso3: "HTI",
          name_fr: "Haïti",
        },
        {
          iso3: "HND",
          name_fr: "Honduras",
        },
        {
          iso3: "HKG",
          name_fr: "Hong Kong (Chine)",
        },
        {
          iso3: "HUN",
          name_fr: "Hongrie",
        },
        {
          iso3: "IMN",
          name_fr: "Île de Man",
        },
        {
          iso3: "MUS",
          name_fr: "Ile Maurice",
        },
        {
          iso3: "CYM",
          name_fr: "Îles Caïmans",
        },
        {
          iso3: "FRO",
          name_fr: "Îles Féroé",
        },
        {
          iso3: "MNP",
          name_fr: "Îles Mariannes du Nord",
        },
        {
          iso3: "MHL",
          name_fr: "Îles Marshall",
        },
        {
          iso3: "SLB",
          name_fr: "Îles Salomon",
        },
        {
          iso3: "TCA",
          name_fr: "Îles Turques-et-Caïques",
        },
        {
          iso3: "VGB",
          name_fr: "Îles Vierges britanniques",
        },
        {
          iso3: "IND",
          name_fr: "Inde",
        },
        {
          iso3: "IDN",
          name_fr: "Indonésie",
        },
        {
          iso3: "IRQ",
          name_fr: "Irak",
        },
        {
          iso3: "IRN",
          name_fr: "Iran",
        },
        {
          iso3: "IRL",
          name_fr: "Irlande",
        },
        {
          iso3: "ISL",
          name_fr: "Islande",
        },
        {
          iso3: "ISR",
          name_fr: "Israël",
        },
        {
          iso3: "ITA",
          name_fr: "Italie",
        },
        {
          iso3: "JAM",
          name_fr: "Jamaïque",
        },
        {
          iso3: "JPN",
          name_fr: "Japon",
        },
        {
          iso3: "JOR",
          name_fr: "Jordanie",
        },
        {
          iso3: "KAZ",
          name_fr: "Kazakhstan",
        },
        {
          iso3: "KEN",
          name_fr: "Kenya",
        },
        {
          iso3: "KGZ",
          name_fr: "Kirghizistan",
        },
        {
          iso3: "KIR",
          name_fr: "Kiribati",
        },
        {
          iso3: "KOS",
          name_fr: "Kosovo",
        },
        {
          iso3: "KWT",
          name_fr: "Koweït",
        },
        {
          iso3: "LAO",
          name_fr: "Laos",
        },
        {
          iso3: "LSO",
          name_fr: "Lesotho",
        },
        {
          iso3: "LVA",
          name_fr: "Lettonie",
        },
        {
          iso3: "LBN",
          name_fr: "Liban",
        },
        {
          iso3: "LBR",
          name_fr: "Liberia",
        },
        {
          iso3: "LBY",
          name_fr: "Libye",
        },
        {
          iso3: "LIE",
          name_fr: "Liechtenstein",
        },
        {
          iso3: "LTU",
          name_fr: "Lituanie",
        },
        {
          iso3: "LUX",
          name_fr: "Luxembourg",
        },
        {
          iso3: "MAC",
          name_fr: "Macao (Chine)",
        },
        {
          iso3: "MKD",
          name_fr: "Macédoine du Nord",
        },
        {
          iso3: "MDG",
          name_fr: "Madagascar",
        },
        {
          iso3: "MYS",
          name_fr: "Malaisie",
        },
        {
          iso3: "MWI",
          name_fr: "Malawi",
        },
        {
          iso3: "MDV",
          name_fr: "Maldives",
        },
        {
          iso3: "MLI",
          name_fr: "Mali",
        },
        {
          iso3: "MLT",
          name_fr: "Malte",
        },
        {
          iso3: "MAR",
          name_fr: "Maroc",
        },
        {
          iso3: "MRT",
          name_fr: "Mauritanie",
        },
        {
          iso3: "MEX",
          name_fr: "Mexique",
        },
        {
          iso3: "FSM",
          name_fr: "Micronésie",
        },
        {
          iso3: "MDA",
          name_fr: "Moldavie",
        },
        {
          iso3: "MCO",
          name_fr: "Monaco",
        },
        {
          iso3: "MNG",
          name_fr: "Mongolie",
        },
        {
          iso3: "MNE",
          name_fr: "Monténégro",
        },
        {
          iso3: "MOZ",
          name_fr: "Mozambique",
        },
        {
          iso3: "NAM",
          name_fr: "Namibie",
        },
        {
          iso3: "NRU",
          name_fr: "Nauru",
        },
        {
          iso3: "NPL",
          name_fr: "Népal",
        },
        {
          iso3: "NIC",
          name_fr: "Nicaragua",
        },
        {
          iso3: "NER",
          name_fr: "Niger",
        },
        {
          iso3: "NGA",
          name_fr: "Nigéria",
        },
        {
          iso3: "NOR",
          name_fr: "Norvège",
        },
        {
          iso3: "NZL",
          name_fr: "Nouvelle-Zélande",
        },
        {
          iso3: "OMN",
          name_fr: "Oman",
        },
        {
          iso3: "UZB",
          name_fr: "Ouzbékistan",
        },
        {
          iso3: "PAK",
          name_fr: "Pakistan",
        },
        {
          iso3: "PLW",
          name_fr: "Palaos",
        },
        {
          iso3: "PAN",
          name_fr: "Panama",
        },
        {
          iso3: "PNG",
          name_fr: "Papouasie-Nouvelle-Guinée",
        },
        {
          iso3: "PRY",
          name_fr: "Paraguay",
        },
        {
          iso3: "NLD",
          name_fr: "Pays-Bas",
        },
        {
          iso3: "PER",
          name_fr: "Pérou",
        },
        {
          iso3: "PHL",
          name_fr: "Philippines",
        },
        {
          iso3: "POL",
          name_fr: "Pologne",
        },
        {
          iso3: "PRI",
          name_fr: "Porto Rico",
        },
        {
          iso3: "PRT",
          name_fr: "Portugal",
        },
        {
          iso3: "QAT",
          name_fr: "Qatar",
        },
        {
          iso3: "CA-QC",
          name_fr: "Québec",
        },
        {
          iso3: "CAF",
          name_fr: "République centrafricaine",
        },
        {
          iso3: "DOM",
          name_fr: "République dominicaine",
        },
        {
          iso3: "CZE",
          name_fr: "République tchèque",
        },
        {
          iso3: "ROU",
          name_fr: "Roumanie",
        },
        {
          iso3: "GBR",
          name_fr: "Royaume-Uni",
        },
        {
          iso3: "RUS",
          name_fr: "Russie",
        },
        {
          iso3: "RWA",
          name_fr: "Rwanda",
        },
        {
          iso3: "SXM",
          name_fr: "Saint Martin (partie néerlandaise)",
        },
        {
          iso3: "KNA",
          name_fr: "Saint-Christophe-et-Niévès",
        },
        {
          iso3: "SMR",
          name_fr: "Saint-Marin",
        },
        {
          iso3: "MAF",
          name_fr: "Saint-Martin (partie française)",
        },
        {
          iso3: "VCT",
          name_fr: "Saint-Vincent-et-les-Grenadines",
        },
        {
          iso3: "LCA",
          name_fr: "Sainte-Lucie",
        },
        {
          iso3: "SLV",
          name_fr: "Salvador",
        },
        {
          iso3: "WSM",
          name_fr: "Samoa",
        },
        {
          iso3: "ASM",
          name_fr: "Samoa américaines",
        },
        {
          iso3: "STP",
          name_fr: "Sao Tomé-et-Principe",
        },
        {
          iso3: "SEN",
          name_fr: "Sénégal",
        },
        {
          iso3: "SRB",
          name_fr: "Serbie",
        },
        {
          iso3: "SYC",
          name_fr: "Seychelles",
        },
        {
          iso3: "SLE",
          name_fr: "Sierra Leone",
        },
        {
          iso3: "SGP",
          name_fr: "Singapour",
        },
        {
          iso3: "SVK",
          name_fr: "Slovaquie",
        },
        {
          iso3: "SVN",
          name_fr: "Slovénie",
        },
        {
          iso3: "SOM",
          name_fr: "Somalie",
        },
        {
          iso3: "SDN",
          name_fr: "Soudan",
        },
        {
          iso3: "SSD",
          name_fr: "Soudan du Sud",
        },
        {
          iso3: "LKA",
          name_fr: "Sri Lanka",
        },
        {
          iso3: "SWE",
          name_fr: "Suède",
        },
        {
          iso3: "CHE",
          name_fr: "Suisse",
        },
        {
          iso3: "SUR",
          name_fr: "Suriname",
        },
        {
          iso3: "SYR",
          name_fr: "Syrie",
        },
        {
          iso3: "TJK",
          name_fr: "Tadjikistan",
        },
        {
          iso3: "TWN",
          name_fr: "Taïwan",
        },
        {
          iso3: "TZA",
          name_fr: "Tanzanie",
        },
        {
          iso3: "TCD",
          name_fr: "Tchad",
        },
        {
          iso3: "PSE",
          name_fr: "Territoires palestiniens",
        },
        {
          iso3: "THA",
          name_fr: "Thaïlande",
        },
        {
          iso3: "TLS",
          name_fr: "Timor oriental",
        },
        {
          iso3: "TGO",
          name_fr: "Togo",
        },
        {
          iso3: "TON",
          name_fr: "Tonga",
        },
        {
          iso3: "TTO",
          name_fr: "Trinité et Tobago",
        },
        {
          iso3: "TUN",
          name_fr: "Tunisie",
        },
        {
          iso3: "TKM",
          name_fr: "Turkménistan",
        },
        {
          iso3: "TUR",
          name_fr: "Turquie",
        },
        {
          iso3: "TUV",
          name_fr: "Tuvalu",
        },
        {
          iso3: "UGA",
          name_fr: "Uganda",
        },
        {
          iso3: "UKR",
          name_fr: "Ukraine",
        },
        {
          iso3: "URY",
          name_fr: "Uruguay",
        },
        {
          iso3: "VUT",
          name_fr: "Vanuatu",
        },
        {
          iso3: "VAT",
          name_fr: "Vatican",
        },
        {
          iso3: "VEN",
          name_fr: "Venezuela",
        },
        {
          iso3: "VNM",
          name_fr: "Viêt Nam",
        },
        {
          iso3: "YEM",
          name_fr: "Yémen",
        },
        {
          iso3: "ZMB",
          name_fr: "Zambie",
        },
        {
          iso3: "ZWE",
          name_fr: "Zimbabwe",
        },
      ];

      const options4 = arrycountries.map((data, index) => {
        return {
          label: data.name_fr,
          value: data.name_fr,
          key: index,
        };
      });
      this.setState({
        countries: options4,
      });
      // add arry statuts

      const arryStatuts = [
        {
          name: "Médecin hospitalier",
        },
        {
          name: "Médecin en exercice libéral",
        },
        {
          name: "Médecin en exercice mixte",
        },
        {
          name: "Médecin étranger",
        },
        {
          name: "Interne",
        },
        {
          name: "Etudiant en faculté de médecine",
        },
        {
          name: "Industrie – annonceur",
        },
        {
          name: "Autre",
        },
      ];

      const options5 = arryStatuts.map((data, index) => {
        return {
          label: data.name,
          value: data.name,
          key: index,
        };
      });
      this.setState({
        statuts: options5,
      });
    }, 200);
  }

  handleChangePrenom(event) {
    this.setState({ prenom: event.target.value });
  }
  handleChangePrecision(event) {
    this.setState({ Precision: event.target.value });
  }

  handleChangePrecisionCentre(event) {
    this.setState({ PrecisionCentre: event.target.value });
  }

  handleChangeLaboratoire(event) {
    this.setState({ Laboratoire: event.target.value });
  }

  handleChangeVille(event) {
    this.setState({ ville: event.target.value });
  }

  handleChangeNom(event) {
    this.setState({ nom: event.target.value });
  }

  handleChangeEmailP(event) {
    this.setState({ emailP: event.target.value });
  }

  handleChangeEmailS(event) {
    this.setState({ emailS: event.target.value });
  }

  handleChangePassword(event) {
    this.setState({ password: event.target.value });
  }

  handleChangeRGPD(event) {
    this.setState({ rgpd: !this.state.rgpd });
    // console.log("rgpd :" + !this.state.rgpd);
  }
  handleChangeOeil = () => {
    this.setState({ visible: !this.state.visible });
  };

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validateMDP(mdp) {
    const res = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    return res.test(mdp);
  }

  handleChangeAlerte(event) {
    this.setState({ tmpAlerte: 1 });

    setTimeout(() => {
      this.setState({
        tmpAlerte: 0,
      });
    }, "3000");
  }

  handleChangeAlerteEmail(event) {
    this.setState({ tmpAlerteEmail: 1 });

    setTimeout(() => {
      this.setState({
        tmpAlerteEmail: 0,
      });
    }, "3000");
  }

  handleChangeAlertePassword(event) {
    this.setState({ tmplateAlertePassword: 1 });

    setTimeout(() => {
      this.setState({
        tmplateAlertePassword: 0,
      });
    }, "5000");
  }

  handleChangeAlerteUserExiste(event) {
    this.setState({ tmplateAlerteUserExiste: 1 });

    setTimeout(() => {
      this.setState({
        tmplateAlerteUserExiste: 0,
      });
    }, "5000");
  }

  Register() {
    var data = null;

    data = {
      prenom: this.state.prenom,
      nom: this.state.nom,
      email: this.state.emailP,
      email_sec: this.state.emailS,
      spePrecise: this.state.Precision,
      laboratoire: this.state.Laboratoire,
      password: this.state.password,
      pays: this.state.pays.value,
      departement: this.state.departement.value,
      specialite: this.state.currentObjectSpecialite.value,
      centre: this.state.currentObjectCenter.value,
      status: this.state.currentObjectHospitalier.value,
    };

    if (this.state.statut.value == "Autre") {
      this.state.statut.value = this.state.Precision;
    }

    if (this.state.centre.value == "Autre") {
      this.state.centre.value = this.state.PrecisionCentre;
    }

    if (this.state.currentObjectSpecialite.value == "Autre") {
      this.state.currentObjectSpecialite.value = this.state.specialiteAll.value;
    }

    if (data) {
      var pays = "";
      var departement = "";
      var centre = "";
      var specialite = "";
      var statut = "";
      if (this.state.pays.value == undefined) {
        pays = "";
      } else {
        pays = this.state.pays.value;
      }

      if (this.state.departement.value == undefined) {
        departement = "";
      } else {
        departement = this.state.departement.value;
      }

      if (this.state.centre == undefined) {
        centre = "";
      } else if (typeof this.state.centre === "object") {
        centre = this.state.centre.value;
      } else {
        centre = this.state.centre;
      }

      if (this.state.specialite == undefined) {
        specialite = "";
      } else if (typeof this.state.specialite === "object") {
        //console.log("deuxieme");
        specialite = this.state.specialite.value;
      } else if (typeof this.state.specialite === "string") {
        //console.log("troisieme");
        specialite = this.state.specialite;
      }

      if (this.state.statut == undefined) {
        statut = "";
      } else if (typeof this.state.statut === "object") {
        //console.log("deuxieme");
        statut = this.state.statut.value;
      } else if (typeof this.state.statut === "string") {
        //console.log("troisieme");
        statut = this.state.statut;
      }

      axios
        .post(`https://serveur.thewhitecoats.com/auth/register`, {
          nom: this.state.nom,
          prenom: this.state.prenom,
          email: this.state.emailP,
          password: this.state.password,
          spePrecise: this.state.Precision,
          laboratoire: this.state.Laboratoire,
          ville: this.state.ville,
          pays: pays,
          departement: departement,
          email_sec: this.state.emailS,
          status: this.state.currentObjectHospitalier.value,
          specialite: this.state.currentObjectSpecialite.value,
          centre: this.state.currentObjectCenter.value,
        })
        .then((res) => {
          //console.log(res.status);
          if (res.status == 200) {
            this.handleChangeAlerteUserExiste();
          } else {
            localStorage.setItem("Token", res.data.token);
            localStorage.setItem("email", this.state.emailP);
            window.location.reload(false);
          }
        })
        .catch((err) => {
          alert("Erreur, veuillez réessayer ");
          console.log(err);
        });
    }
  }

  handleSubmit(event) {
    if (
      this.validateEmail(this.state.emailP) &&
      this.validateMDP(this.state.password)
    ) {
      if (
        this.state.currentObjectHospitalier.value ==
        "Médecin en exercice libéral"
      ) {
        if (
          this.state.prenom &&
          this.state.nom &&
          this.state.emailP &&
          this.state.password &&
          this.state.currentObjectSpecialite.value &&
          this.state.currentObjectCenter.value &&
          this.state.currentObjectHospitalier.value
        ) {
          this.Register();
        } else {
          if (
            this.state.prenom !== "" ||
            this.state.nom !== "" ||
            this.state.emailP !== "" ||
            this.state.currentObjectSpecialite.value !== "" ||
            this.state.password !== "" ||
            this.state.ville !== "" ||
            this.state.currentObjectHospitalier !== "" ||
            this.state.currentObjectCenter !== ""
          ) {
            this.handleChangeAlerte();
          } else if (!this.validateEmail(this.state.emailP)) {
            this.handleChangeAlerteEmail();
          } else if (!this.validateMDP(this.state.password)) {
            this.handleChangeAlertePassword();
          } else {
            this.handleChangeAlerte();
          }
        }
      } else if (
        this.state.currentObjectHospitalier.value == "Médecin étranger"
      ) {
        if (
          this.state.prenom &&
          this.state.nom &&
          this.state.emailP &&
          this.validateEmail(this.state.emailP) &&
          this.state.currentObjectSpecialite.value &&
          this.state.password &&
          this.state.pays &&
          this.state.ville &&
          this.state.statut
        ) {
          this.Register();
        } else {
          if (
            this.state.prenom !== "" ||
            this.state.nom !== "" ||
            this.state.emailP !== "" ||
            this.state.currentObjectSpecialite.value !== "" ||
            this.state.password !== "" ||
            this.state.pays !== "" ||
            this.state.ville !== "" ||
            this.state.statut !== ""
          ) {
            this.handleChangeAlerte();
          } else if (!this.validateEmail(this.state.emailP)) {
            this.handleChangeAlerteEmail();
          } else if (!this.validateMDP(this.state.password)) {
            this.handleChangeAlertePassword();
          } else {
            this.handleChangeAlerte();
          }
        }
      } else if (
        this.state.currentObjectHospitalier.value == "Médecin hospitalier" ||
        this.state.currentObjectHospitalier.value ==
          "Médecin en exercice mixte" ||
        this.state.currentObjectHospitalier.value == "Interne"
      ) {
        if (
          this.state.prenom &&
          this.state.nom &&
          this.state.emailP &&
          this.state.currentObjectSpecialite.value &&
          this.state.password &&
          this.state.currentObjectHospitalier &&
          this.state.currentObjectCenter.value
        ) {
          this.Register();
        } else {
          if (
            this.state.prenom !== "" ||
            this.state.nom !== "" ||
            this.state.emailP !== "" ||
            this.state.currentObjectSpecialite.value !== "" ||
            this.state.password !== "" ||
            this.state.currentObjectCenter.value !== "" ||
            this.state.currentObjectHospitalier.value !== ""
          ) {
            this.handleChangeAlerte();
            //console.log("premier2");
          } else if (!this.validateEmail(this.state.emailP)) {
            this.handleChangeAlerteEmail();
          } else if (!this.validateMDP(this.state.password)) {
            this.handleChangeAlertePassword();
          } else {
            this.handleChangeAlerte();
          }
        }
      } else if (this.state.currentObjectHospitalier.value == "Autre") {
        if (
          this.state.prenom &&
          this.state.nom &&
          this.state.emailP &&
          this.validateEmail(this.state.emailP) &&
          this.state.currentObjectSpecialite.value &&
          this.state.currentObjectCenter.value &&
          this.state.password &&
          this.state.Precision &&
          this.state.currentObjectHospitalier.value
        ) {
          this.Register();
        } else {
          if (
            this.state.prenom !== "" ||
            this.state.nom !== "" ||
            this.state.emailP !== "" ||
            this.state.currentObjectSpecialite.value !== "" ||
            this.state.password !== "" ||
            this.state.currentObjectCenter.value !== "" ||
            this.state.Precision !== "" ||
            this.state.currentObjectHospitalier.value !== ""
          ) {
            this.handleChangeAlerte();
            //console.log("deuxieme");
          } else if (!this.validateEmail(this.state.emailP)) {
            this.handleChangeAlerteEmail();
          } else if (!this.validateMDP(this.state.password)) {
            this.handleChangeAlertePassword();
          } else {
            this.handleChangeAlerte();
          }
        }
      } else if (
        this.state.currentObjectHospitalier.value == "Industrie – annonceur" ||
        this.state.currentObjectHospitalier.value == "Industrie – annonceur"
      ) {
        if (
          this.state.prenom &&
          this.state.nom &&
          this.state.emailP &&
          this.state.password &&
          this.state.Laboratoire &&
          this.state.currentObjectHospitalier.value
        ) {
          this.Register();
        } else {
          if (
            this.state.prenom !== "" ||
            this.state.nom !== "" ||
            this.state.emailP !== "" ||
            this.state.password !== "" ||
            this.state.Laboratoire !== "" ||
            this.state.currentObjectHospitalier.value !== ""
          ) {
            this.handleChangeAlerte();
            //console.log("troisiemme");
          } else if (!this.validateEmail(this.state.emailP)) {
            this.handleChangeAlerteEmail();
          } else if (!this.validateMDP(this.state.password)) {
            this.handleChangeAlertePassword();
          } else {
            this.handleChangeAlerte();
          }
        }
      } else {
        if (
          this.state.prenom &&
          this.state.nom &&
          this.state.emailP &&
          this.state.currentObjectSpecialite.value &&
          this.state.password &&
          this.state.currentObjectHospitalier.value &&
          this.state.currentObjectCenter.value
        ) {
          this.Register();
        } else {
          if (
            this.state.prenom !== "" ||
            this.state.nom !== "" ||
            this.state.emailP !== "" ||
            this.state.currentObjectSpecialite.value !== "" ||
            this.state.password !== "" ||
            this.state.currentObjectHospitalier.value !== "" ||
            this.state.currentObjectCenter.value !== ""
          ) {
            this.handleChangeAlerte();
            //console.log("dernier");
          } else if (!this.validateEmail(this.state.emailP)) {
            this.handleChangeAlerteEmail();
          } else if (!this.validateMDP(this.state.password)) {
            this.handleChangeAlertePassword();
          } else {
            this.handleChangeAlerte();
          }
        }
      }
    } else {
      if (!this.validateEmail(this.state.emailP)) {
        this.handleChangeAlerteEmail();
      } else if (!this.validateMDP(this.state.password)) {
        this.handleChangeAlertePassword();
      }
    }
  }

  handleChangeSupprimer(event) {
    axios
      .post(`https://serveur.thewhitecoats.com/users/deleteUserNotValidated`, {
        email: this.state.emailP,
      })
      .then((res) => {
        alert("Vos informations ont bien été supprimer");
        window.location.reload(false);
        //console.log(res);
      })
      .catch((err) => {
        //alert("Erreur !");
        //console.log(err);
      });
  }

  handleChange = (centre) => {
    if (
      centre.value ===
      "........................................................"
    ) {
      this.setState({ centre: "" });
    } else {
      this.setState({ centre }, () =>
        console.log(`Option selected:`, this.state.centre.value)
      );
    }
  };

  handleChangeSpecialite = (specialite) => {
    if (
      specialite.value ===
      "........................................................"
    ) {
      this.setState({ specialite: "" });
    } else {
      this.setState({ specialite }, () =>
        console.log(`Option selected:`, this.state.specialite.value)
      );
    }
  };

  handleChangeSpecialiteAll = (specialiteAll) => {
    if (
      specialiteAll.value ===
      "........................................................"
    ) {
      this.setState({ specialiteAll: "" });
    } else {
      this.setState({ specialiteAll }, () =>
        console.log(`Option selected:`, this.state.specialiteAll.value)
      );
    }
  };

  handleChangeDepartements = (departement) => {
    this.setState({ departement }, () =>
      console.log(`Option selected:`, this.state.departement.value)
    );
  };

  handleChangePays = (pays) => {
    this.setState({ pays }, () =>
      console.log(`Option selected:`, this.state.pays.value)
    );
  };

  handleChangeStatut = (statut) => {
    this.setState({ statut }, () =>
      console.log(`Option selected:`, this.state.statut.value)
    );
  };

  handleChangeStatut2 = (currentObjectHospitalier) => {
    this.setState({ currentObjectHospitalier }, () =>
      console.log(`Option selected:`, this.state.currentObjectHospitalier)
    );
  };

  handleChangeCentre2 = (currentObjectCenter) => {
    this.setState({ currentObjectCenter }, () =>
      console.log(`Option selected:`, this.state.currentObjectCenter)
    );
  };

  handleChangeSpecialite2 = (currentObjectSpecialite) => {
    this.setState({ currentObjectSpecialite }, () =>
      console.log(`Option selected:`, this.state.currentObjectSpecialite)
    );
  };

  handleSubmitPassword(event) {
    if (this.validateMDP(this.state.password)) {
      var data = null;

      data = {
        password: this.state.password,
        token: this.state.tokenPassword,
      };

      if (data) {
        axios
          .post(
            `https://serveur.thewhitecoats.com/users/forgottenPasswordChange`,
            {
              password: this.state.password,
              token: this.state.tokenPassword,
            }
          )
          .then((res) => {
            alert("Votre mot de passe a bien été changé");
            window.location.href = "https://thewhitecoats.com/#/";
          })
          .catch((err) => {
            alert("Erreur");
            console.log(err);
          });
      }
    } else {
      if (!this.validateMDP(this.state.password)) {
        this.handleChangeAlertePassword();
      } else {
        this.handleChangeAlerte();
      }
    }
  }

  handleAfficherInfo = (e) => {
    this.setState({ info: 1 });
  };

  handleCacherInfo = (e) => {
    this.setState({ info: 0 });
  };

  render() {
    const { currentObjectHospitalier } = this.state;
    const { currentObjectSpecialite } = this.state;
    const { currentObjectCenter } = this.state;
    const { centre } = this.state;
    const { specialite } = this.state;
    const { departement } = this.state;
    const { pays } = this.state;
    const { statut } = this.state;

    var templateAlerte = "";
    var templateCentreCHU = "";
    var templateInterneAutre = "";
    var templatePays = "";
    var templateDepartement = "";
    var templateRGPD = "";
    var templateCasscade = "";
    var templateInfo = "";

    //Info

    if (this.state.info == 1) {
      templateInfo = (
        <div className="ContainerInfo">
          Au moins 8 caractères: 1 majuscule, 1 minuscule, <br /> 1 chiffre, 1
          caractère spécial (#?!@$%^&*-)
        </div>
      );
    }

    //Alerte

    if (this.state.tmpAlerte == 1) {
      templateAlerte = (
        <div className="ContainerAlerte">
          Veuillez remplir les champs comportant une étoile
        </div>
      );
    }

    //Alerte Email

    var templateAlerteEmail = "";
    if (this.state.tmpAlerteEmail == 1) {
      templateAlerte = (
        <div className="ContainerAlerte">
          Veuillez entrer une adresse mail valide
        </div>
      );
    }

    //Alerte mot de passe

    var templateAlertePassword = "";
    if (this.state.tmplateAlertePassword == 1) {
      templateAlertePassword = (
        <div className="ContainerAlerte2">
          Votre mot de passe doit contenir au minimum 8 caractères, <br /> à
          savoir : au moins une lettre minuscule et une lettre majuscule, <br />{" "}
          un caractère spécial et un chiffre{" "}
        </div>
      );
    }

    var templateAlerteUserExiste = "";
    if (this.state.tmplateAlerteUserExiste == 1) {
      templateAlerteUserExiste = (
        <div className="ContainerAlerte3">
          Cet email est déjà associé à un compte, veuillez vous <br />
          connecter ou réinitialiser votre mot de passe si vous l’avez oublié 
        </div>
      );
    }

    // RGPD
    if (this.state.rgpd == true) {
      templateRGPD = (
        <div className="containerLayer">
          <div className="containerRGPD">
            <div className="poprgpd">
              En cliquant sur "Supprimer" vous confirmez exercer <br />
              votre droit RGPD et nous supprimerons toutes les <br />
              informations vous concernant.
            </div>

            <div className="btnValider" onClick={this.handleChangeSupprimer}>
              SUPPRIMER
            </div>
            <p
              style={{
                textDecoration: "underline",
                marginTop: 20,
                cursor: "pointer",
              }}
              onClick={this.handleChangeRGPD}
            >
              {" "}
              Non, je ne souhaite pas supprimer mes informations
            </p>
          </div>
        </div>
      );
    }

    // template departement

    if (
      this.state.currentObjectHospitalier.value == "Médecin en exercice libéral"
    ) {
      var templateDepartement = (
        <div className="LastAndFirstName2">
          <div
            style={{
              width: "50%",
              paddingLeft: 10,
              display: "flex",
              marginTop: 25,
            }}
            className="insideDepartement"
          >
            <span className="centreSpecialite"> Departement * : </span>

            <Select
              options={this.state.departements}
              value={departement}
              onChange={this.handleChangeDepartements}
              placeholder="Taper les première lettres"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  backgroundColor: "#3c7493",
                  color: "white",
                }),
                placeholder: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: "#3c7493",
                  color: "white",
                  backgroundColor: "#3c7493",
                }),

                SingleValue: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "white !important",
                }),
              }}
            />
          </div>

          <div
            style={{
              width: "50%",
              paddingLeft: 10,
              display: "flex",
            }}
            className="CentreVille"
          >
            <span
              style={{
                width: "30%",
                marginTop: "30px",
              }}
            >
              {" "}
              Centre / ville * :
            </span>
            <input
              type="text"
              name="Ville"
              required
              value={this.state.ville}
              onChange={this.handleChangeVille}
              style={{
                color: "white",
                width: "60%",
                marginTop: "20px",
                height: "30px",
              }}
            />
          </div>
        </div>
      );
    }

    // medecin etranger

    if (this.state.currentObjectHospitalier.value === "Médecin étranger") {
      var templatePays = (
        <div className="LastAndFirstName2">
          <div
            style={{
              width: "50%",
              paddingLeft: 10,
              display: "flex",
              marginTop: 25,
            }}
            className="insideDepartement"
          >
            <span className="centreSpecialite"> Pays * : </span>

            <Select
              options={this.state.countries}
              value={pays}
              onChange={this.handleChangePays}
              placeholder="Taper les première lettres"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  backgroundColor: "#3c7493",
                  color: "white",
                }),
                placeholder: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: "#3c7493",
                  color: "white",
                  backgroundColor: "#3c7493",
                }),

                SingleValue: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "white !important",
                }),
              }}
            />
          </div>

          <div
            style={{
              width: "50%",
              paddingLeft: 10,
              display: "flex",
            }}
            className="CentreVille"
          >
            <span
              style={{
                width: "30%",
                marginTop: "30px",
              }}
            >
              {" "}
              Centre / ville * :
            </span>
            <input
              type="text"
              name="ville"
              required
              value={this.state.ville}
              onChange={this.handleChangeVille}
              style={{
                color: "white",
                width: "60%",
                marginTop: "20px",
                height: "30px",
              }}
            />
          </div>
        </div>
      );
    }

    // template statut

    var templateStatut = (
      <div
        style={{
          width: "50%",
          paddingLeft: 10,
          display: "flex",
          marginTop: 25,
        }}
        className="insideStatutSpecialite"
      >
        <span className="SelectNameCentre">Statut * : </span>

        <Select
          options={this.state.statuts}
          value={this.state.currentObjectHospitalier}
          onChange={this.handleChangeStatut2}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: "#3c7493",
              color: "white",
            }),
            placeholder: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: "#3c7493",
              color: "white",
              backgroundColor: "#3c7493",
            }),

            SingleValue: (baseStyles, state) => ({
              ...baseStyles,
              color: "white !important",
            }),
          }}
        />
      </div>
    );

    // Spécialite
    var templateSpecialite = (
      <div
        style={{
          width: "50%",
          paddingLeft: 10,
          display: "flex",
          marginTop: 25,
        }}
        className="insideStatutSpecialite"
      >
        <span className="SelectNameSpecialite"> Spécialité * : </span>

        <Select
          options={this.state.specialites}
          value={this.state.currentObjectSpecialite}
          onChange={this.handleChangeSpecialite2}
          placeholder="Taper les première lettres"
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: "#3c7493",
              color: "white",
            }),
            placeholder: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: "#3c7493",
              color: "white",
              backgroundColor: "#3c7493",
            }),

            SingleValue: (baseStyles, state) => ({
              ...baseStyles,
              color: "white !important",
            }),
          }}
        />
      </div>
    );

    // Tempate interne et autre
    if (this.state.currentObjectHospitalier.value == "Autre") {
      templateInterneAutre = (
        <div
          style={{
            width: "50%",
            paddingLeft: 10,
            display: "inline-block",
          }}
        >
          Préciser * :
          <input
            type="text"
            className="NomPrenom"
            name="Autre"
            required
            value={this.state.Precision}
            onChange={this.handleChangePrecision}
          />
        </div>
      );
    }

    // Tempate centre autre
    if (this.state.centre.value == "Autre") {
      var templateCentreAutre = (
        <div
          style={{
            width: "50%",
            paddingLeft: 10,
            display: "inline-block",
          }}
        >
          Préciser * :
          <input
            type="text"
            className="NomPrenom"
            name="Centre AUtre"
            required
            value={this.state.PrecisionCentre}
            onChange={this.handleChangePrecisionCentre}
          />
        </div>
      );
    }

    // Tempate interne specialité
    if (this.state.currentObjectSpecialite.value == "Autre") {
      var templateSpecialiteAutre = (
        <div
          style={{
            width: "50%",
            paddingLeft: 10,
            display: "flex",
            marginTop: 25,
          }}
        >
          <span className="SelectNameSpecialite"> Préciser * : </span>

          <Select
            options={this.state.specialitesAll}
            value={this.state.specialiteAll}
            onChange={this.handleChangeSpecialiteAll}
            placeholder="Taper les première lettres"
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: "#3c7493",
                color: "white",
              }),
              placeholder: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: "#3c7493",
                color: "white",
                backgroundColor: "#3c7493",
              }),

              SingleValue: (baseStyles, state) => ({
                ...baseStyles,
                color: "white !important",
              }),
            }}
          />
        </div>
      );
    }

    // Tempate indistrie
    if (this.state.currentObjectHospitalier.value == "Industrie – annonceur") {
      templateCentreComplet = (
        <div
          style={{
            width: "50%",
            paddingLeft: 10,
            display: "flex",
          }}
        >
          <span
            style={{
              width: "30%",
              marginTop: "30px",
            }}
          >
            {" "}
            Laboratoire * :
          </span>
          <input
            type="text"
            name="Laboratoire"
            required
            value={this.state.Laboratoire}
            onChange={this.handleChangeLaboratoire}
            style={{
              color: "white",
              width: "65%",
              marginTop: "20px",
              height: "30px",
            }}
          />
        </div>
      );
    }
    if (this.state.currentObjectHospitalier.value == "Industrie – annonceur") {
      templateSpecialite = "";
    }

    // Template Etudiant

    if (
      this.state.currentObjectHospitalier.value ==
      "Etudiant en faculté de médecine"
    ) {
      var templateCentreComplet = (
        <div
          style={{
            width: "50%",
            paddingLeft: 10,
            display: "flex",
            marginTop: 25,
          }}
          className="insideCentre"
        >
          <span className="SelectNameCentre"> CHU * : </span>

          <Select
            options={this.state.centers}
            value={this.state.centre}
            onChange={this.handleChange}
            placeholder="Taper les première lettres"
            className="insideStatutSpecialite"
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: "#3c7493",
                color: "white",
              }),
              placeholder: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: "#3c7493",
                color: "white",
                backgroundColor: "#3c7493",
              }),

              SingleValue: (baseStyles, state) => ({
                ...baseStyles,
                color: "white !important",
              }),
            }}
          />
        </div>
      );
    }

    // template centre

    if (
      this.state.currentObjectHospitalier.value !=
        "Etudiant en faculté de médecine" &&
      this.state.currentObjectHospitalier.value != "Industrie – annonceur"
    ) {
      var templateCentreComplet = (
        <div
          style={{
            width: "50%",
            paddingLeft: 10,
            display: "flex",
            marginTop: 25,
          }}
          className="insideCentre"
        >
          <span className="SelectNameCentre">Centre * : </span>

          <Select
            options={this.state.centers}
            value={this.state.currentObjectCenter}
            onChange={this.handleChangeCentre2}
            placeholder="Taper les première lettres"
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: "#3c7493",
                color: "white",
              }),
              placeholder: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: "#3c7493",
                color: "white",
                backgroundColor: "#3c7493",
              }),

              SingleValue: (baseStyles, state) => ({
                ...baseStyles,
                color: "white !important",
              }),
            }}
          />
        </div>
      );
    }

    //template cascade

    if (this.state.etatCasscade == 0) {
      templateCasscade = (
        <div className="LastAndFirstName2">
          {templateStatut}
          {templateSpecialite}
        </div>
      );
    }

    // Réinitialisation du mot de passe
    var templateReinitilalisation = "";

    if (this.state.tokenPassword != "") {
      templateReinitilalisation = (
        <div className="containerLayer">
          <div className="containerRGPD">
            <div className="TitreReinitialisation">
              Afin de réinitialiser votre mot de passe, <br />
              saisissez votre mot de passe ci-dessous. <br />
            </div>
            <div
              style={{
                width: "80%",
                paddingLeft: 10,
                display: "flex",
                marginBottom: 10,
              }}
            >
              <span style={{ marginTop: "40px", width: "50%" }}>
                {" "}
                Nouveau mot de passe * :
              </span>
              <input
                type={this.state.visible ? "text" : "password"}
                name="password"
                required
                value={this.state.password}
                onChange={this.handleChangePassword}
                style={{ width: "50%", marginTop: 34, height: 22 }}
              />
              <div className="visible">
                {this.state.visible ? (
                  <img
                    src="https://thewhitecoats.com/IMG/dev/oeil-barre.png"
                    className="OeilNouveau"
                    onClick={this.handleChangeOeil}
                  />
                ) : (
                  <img
                    src="https://thewhitecoats.com/IMG/dev/oeil.png"
                    className="OeilNouveau"
                    onClick={this.handleChangeOeil}
                  />
                )}
              </div>
            </div>

            <div className="btnValider" onClick={this.handleSubmitPassword}>
              VALIDER
            </div>
          </div>
        </div>
      );
    }

    if (
      this.state.currentObjectHospitalier.value == "Médecin en exercice libéral"
    ) {
      var templateCentreComplet = "";
    }

    if (this.state.currentObjectHospitalier.value == "Médecin étranger") {
      var templateCentreComplet = "";
    }

    var templateRGPDExist = "";

    if (this.props.token) {
      templateRGPDExist = (
        <div className="RGPD">
           {" "}
          <input
            type="radio"
            name="fav_language"
            value={this.state.rgpd}
            onChange={this.handleChangeRGPD}
            checked={this.state.rgpd}
            style={{ marginTop: 15 }}
          />
          <label for="html" className="labelRGPD">
            J'exerce mon droit au RGPD, je n'accepte pas la création de mon
            compte et je demande expressément à être retiré de la mailing-list
            Whitecoats. Je suis conscient que je ne pourrai pas visionner les
            vidéos Whitecoats, mais je me réserve le droit de m'inscrire
            spontanément une autre fois.
          </label>
        </div>
      );
    }

    var templateDefault = "";

    var templateDefault = "";

    if (this.state.currentObjectHospitalier == "Industrie – annonceur") {
      templateDefault = (
        <div>
          <div className="LastAndFirstName">
            <div
              style={{
                width: "50%",
                paddingLeft: 10,
                display: "inline-block",
              }}
              className="PremonMobile"
            >
              Prénom * :
              <input
                type="text"
                className="NomPrenom"
                name="prenom"
                required
                value={this.state.prenom}
                onChange={this.handleChangePrenom}
              />
            </div>

            <div
              style={{
                width: "50%",
                paddingLeft: 10,
                display: "inline-block",
              }}
              className="NomMobile"
            >
              Nom * :
              <input
                type="text"
                className="NomPrenom"
                name="Nom"
                required
                value={this.state.nom}
                onChange={this.handleChangeNom}
              />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              paddingLeft: 10,
              display: "inline-block",
            }}
          >
            Email principal * :
            <input
              type="email"
              className="MailPrincipale"
              name="email"
              required
              value={this.state.emailP}
              onChange={this.handleChangeEmailP}
            />
          </div>
          <div
            style={{
              width: "100%",
              paddingLeft: 10,
              display: "inline-block",
            }}
          >
            Email secondaire (facultatif) :
            <input
              type="email"
              className="MailSecondaire"
              name="email"
              placeholder=""
              value={this.state.emailS}
              onChange={this.handleChangeEmailS}
            />
          </div>

          <div
            style={{
              width: "50%",
              paddingLeft: 10,
              display: "flex",
              marginTop: 25,
            }}
          >
            <span className="SelectNameCentre">Statut * : </span>

            <Select
              options={this.state.statuts}
              value={this.state.currentObjectHospitalier}
              onChange={this.handleChangeStatut2}
              placeholder="Taper les première lettres"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  backgroundColor: "#3c7493",
                  color: "white",
                }),
                placeholder: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: "#3c7493",
                  color: "white",
                  backgroundColor: "#3c7493",
                }),

                SingleValue: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "white !important",
                }),
              }}
            />
          </div>

          <div style={{ display: "flex" }}>
            {templateInterneAutre}
            {templateSpecialiteAutre}
          </div>
          {templateDepartement}
          {templatePays}
          <div style={{ display: "flex" }} className="containerMotdePasse">
            <div
              style={{
                width: "50%",
                paddingLeft: 10,
                display: "flex",
              }}
              className="insideCentre"
            >
              <span
                style={{
                  width: "30%",
                  marginTop: "30px",
                }}
              >
                {" "}
                Laboratoire * :
              </span>
              <input
                type="text"
                name="Laboratoire"
                required
                value={this.state.Laboratoire}
                onChange={this.handleChangeLaboratoire}
                style={{
                  color: "white",
                  width: "65%",
                  marginTop: "20px",
                  height: "30px",
                }}
              />
            </div>

            <div
              style={{
                width: "50%",
                paddingLeft: 10,
                display: "flex",
              }}
            >
              <span className="CPassword"> Choisir un mot de passe*: </span>
              <div className="FlexHide">
                <input
                  type={this.state.visible ? "text" : "password"}
                  className="password"
                  name="password"
                  required
                  value={this.state.password}
                  onChange={this.handleChangePassword}
                />
                <div className="visible">
                  {this.state.visible ? (
                    <img
                      src="https://thewhitecoats.com/IMG/dev/oeil-barre.png"
                      className="OeilRegister"
                      onClick={this.handleChangeOeil}
                    />
                  ) : (
                    <img
                      src="https://thewhitecoats.com/IMG/dev/oeil.png"
                      className="OeilRegister"
                      onClick={this.handleChangeOeil}
                    />
                  )}
                </div>
                <div></div>
              </div>
            </div>
          </div>
          {templateCentreAutre}
          <div className="Consentement">
            En validant la création de mon compte, j'accepte de recevoir les
            newsletters et notifications <br />
            de Whitecoats, et, j'adhère à la{" "}
            <a
              href="https://thewhitecoats.com/Politique-de-confidentialite.html"
              target="_blank"
            >
              politique de confidentialité
            </a>
            de Whitecoats.
          </div>
          <div className="Champs">*Champs obligatoire</div>
          <div className="btnValider" onClick={this.handleSubmit}>
            {" "}
            JE VALIDE MON COMPTE
          </div>
          {templateRGPDExist}
          <div style={{ color: "transparent" }}> .</div>
          {templateAlerte}
          {templateRGPD}
          {templateReinitilalisation}
          {templateAlertePassword}
          {templateAlerteUserExiste}
        </div>
      );
    } else {
      templateDefault = (
        <div>
          <div className="LastAndFirstName">
            <div
              style={{
                width: "50%",
                paddingLeft: 10,
                display: "inline-block",
              }}
              className="PrenomMobile"
            >
              Prénom * :
              <input
                type="text"
                className="NomPrenom"
                name="prenom"
                required
                value={this.state.prenom}
                onChange={this.handleChangePrenom}
              />
            </div>

            <div
              style={{
                width: "50%",
                paddingLeft: 10,
                display: "inline-block",
              }}
              className="NomMobile"
            >
              Nom * :
              <input
                type="text"
                className="NomPrenom"
                name="Nom"
                required
                value={this.state.nom}
                onChange={this.handleChangeNom}
              />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              paddingLeft: 10,
              display: "inline-block",
            }}
            className="EmailPMobile"
          >
            Email principal * :
            <input
              type="email"
              className="MailPrincipale"
              name="email"
              required
              value={this.state.emailP}
              onChange={this.handleChangeEmailP}
            />
          </div>
          <div
            style={{
              width: "100%",
              paddingLeft: 10,
              display: "inline-block",
            }}
            className="EmailSMobile"
          >
            Email secondaire (facultatif) :
            <input
              type="email"
              className="MailSecondaire"
              name="email"
              placeholder=""
              value={this.state.emailS}
              onChange={this.handleChangeEmailS}
            />
          </div>
          {templateCasscade}

          <div style={{ display: "flex" }}>
            {templateInterneAutre}
            {templateSpecialiteAutre}
          </div>
          {templateDepartement}
          {templatePays}

          <div style={{ display: "flex" }} className="containerMotdePasse">
            {templateCentreComplet}

            <div
              style={{
                width: "50%",
                paddingLeft: 10,
                display: "flex",
              }}
              className="insideCentre"
            >
              <span className="CPassword"> Choisir un mot de passe*: </span>
              <div className="FlexHide">
                <input
                  type={this.state.visible ? "text" : "password"}
                  className="password"
                  name="password"
                  required
                  value={this.state.password}
                  onChange={this.handleChangePassword}
                />
                <div className="visible">
                  {this.state.visible ? (
                    <img
                      src="https://thewhitecoats.com/IMG/dev/oeil-barre.png"
                      className="OeilRegister"
                      onClick={this.handleChangeOeil}
                    />
                  ) : (
                    <img
                      src="https://thewhitecoats.com/IMG/dev/oeil.png"
                      className="OeilRegister"
                      onClick={this.handleChangeOeil}
                    />
                  )}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-info-circle"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="1"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    onMouseEnter={this.handleAfficherInfo}
                    onMouseLeave={this.handleCacherInfo}
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                    <path d="M12 9h.01"></path>
                    <path d="M11 12h1v4h1"></path>
                  </svg>
                  {templateInfo}
                </div>
              </div>
            </div>
          </div>
          {templateCentreAutre}

          <div className="Consentement">
            En validant la création de mon compte, j'accepte de recevoir les
            newsletters et notifications <br />
            de Whitecoats, et, j'adhère à la{" "}
            <a
              href="https://thewhitecoats.com/Politique-de-confidentialite.html"
              target="_blank"
            >
              politique de confidentialité
            </a>
            de Whitecoats.
          </div>
          <div className="Champs">*Champs obligatoire</div>
          <div className="btnValider" onClick={this.handleSubmit}>
            {" "}
            JE VALIDE MON COMPTE
          </div>

          {templateRGPDExist}

          <div style={{ color: "transparent" }}> .</div>
          {templateAlerte}
          {templateRGPD}
          {templateReinitilalisation}
          {templateAlertePassword}
          {templateAlerteUserExiste}
        </div>
      );
    }

    return <div className="ContainerRegister">{templateDefault}</div>;
  }
}

export default Register;
