import React, { Component } from "react";
import "./Login.css";
import axios from "axios";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailP: "",
      password: "",
      popup: false,
      tmpAlerte: 0,
      visible: true,
    };

    this.handleChangeEmailP = this.handleChangeEmailP.bind(this);

    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePopup = this.handlePopup.bind(this);
    this.handleSubmitEmail = this.handleSubmitEmail.bind(this);
    this.handleChangeAlerte = this.handleChangeAlerte.bind(this);
    this.handleChangeAlerteEmail = this.handleChangeAlerteEmail.bind(this);
  }
  componentWillMount() {}
  componentDidMount() {
    console.log(this.props.token);
  }

  handleChangeEmailP(event) {
    this.setState({ emailP: event.target.value });
  }

  handleChangePassword(event) {
    this.setState({ password: event.target.value });
  }

  handlePopup(event) {
    this.setState({ popup: !this.state.popup });
  }

  handleChangeOeil = () => {
    this.setState({ visible: !this.state.visible });
  };

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  handleChangeAlerte(event) {
    this.setState({ tmpAlerte: 1 });

    setTimeout(() => {
      this.setState({
        tmpAlerte: 0,
      });
    }, "3000");
  }

  handleChangeAlerteEmail(event) {
    this.setState({ tmpAlerteEmail: 1 });

    setTimeout(() => {
      this.setState({
        tmpAlerteEmail: 0,
      });
    }, "3000");
  }

  handleSubmit(event) {
    if (this.validateEmail(this.state.emailP) && this.state.password) {
      var data = null;

      data = {
        email: this.state.emailP,
        password: this.state.password,
      };

      if (data) {
        axios
          .post(`https://serveur.thewhitecoats.com/auth/login`, {
            email: this.state.emailP,
            password: this.state.password,
          })
          .then((res) => {
            localStorage.setItem("Token", res.data.token);
            localStorage.setItem("email", this.state.emailP);

            window.location.reload(false);
            console.log(res);
          })
          .catch((err) => {
            this.handleChangeAlerte();
            console.log(err);
          });
      }
    } else {
      if (!this.state.emailP || !this.state.password) {
        alert("Veillez remplir tous les champs");
        //this.handleChangeAlerte();
      } else if (!this.validateEmail(this.state.emailP)) {
        this.handleChangeAlerteEmail();
      } else {
        this.handleChangeAlerte();
      }
    }
  }

  handleSubmitEmail(event) {
    if (this.validateEmail(this.state.emailP)) {
      var data = null;

      data = {
        email: this.state.emailP,
      };

      if (data) {
        axios
          .post(`https://serveur.thewhitecoats.com/users/forgottenPassword`, {
            email: this.state.emailP,
          })
          .then((res) => {
            window.location.reload(false);
            console.log(res);
            alert(
              "Un email contenant un lien de réinitialisation vous a été envoyé"
            );
          })
          .catch((err) => {
            alert(
              "Une erreur est survenue. Veuillez vérifier votre adresse e-mail"
            );
            console.log(err);
          });
      }
    } else {
      if (!this.validateEmail(this.state.emailP)) {
        //alert("Veillez entrer une adresse email valide");
        this.handleChangeAlerteEmail();
      } else {
        this.handleChangeAlerte();
      }
    }
  }

  render() {
    var templateAlerte = "";

    if (this.state.tmpAlerte == 1) {
      templateAlerte = (
        <div className="ContainerAlerte">Email ou mot de passe incorrect </div>
      );
    }

    //Alerte Email

    var templateAlerteEmail = "";
    if (this.state.tmpAlerteEmail == 1) {
      templateAlerte = (
        <div className="ContainerAlerte">
          Veuillez entrer une adresse mail valide
        </div>
      );
    }

    var templateReinitilalisation = "";
    if (this.state.popup == true) {
      templateReinitilalisation = (
        <div className="containerLayer">
          <div className="containerRGPD">
            <div className="TitreReinitialisation">
              Vous avez oublié votre mot de passe ? <br />
              Saisissez votre adresse électronique et nous vous <br />
              enverrons un lien de réinitialisation.
            </div>
            <div
              style={{
                width: "80%",
                paddingLeft: 10,
                display: "inline-block",
                marginBottom: 10,
              }}
            >
              Email*:
              <input
                type="text"
                className="inputLogin"
                name="email"
                required
                value={this.state.emailP}
                onChange={this.handleChangeEmailP}
              />
            </div>

            <div className="btnValider" onClick={this.handleSubmitEmail}>
              VALIDER
            </div>
            <p
              style={{
                textDecoration: "underline",
                marginTop: 20,
                cursor: "pointer",
                fontSize: 12,
              }}
              onClick={this.handlePopup}
            >
              {" "}
              Non, je ne souhaite pas réinitialiser mon mot de passe.
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="ContainerLogin">
        <div
          style={{
            width: "100%",
            paddingLeft: 10,
            display: "inline-block",
          }}
        >
          Email*:
          <input
            type="text"
            className="inputLogin"
            name="email"
            required
            value={this.state.emailP}
            onChange={this.handleChangeEmailP}
          />
        </div>
        <div
          style={{
            width: "100%",
            paddingLeft: 10,
            display: "flex",
          }}
        >
          <span className="txtPasswordLogin"> Mot de passe*:</span>

          <input
            type={this.state.visible ? "text" : "password"}
            className="inputPassword"
            name="password"
            required
            value={this.state.password}
            onChange={this.handleChangePassword}
          />
          <div className="visible">
            {this.state.visible ? (
              <img
                src="https://thewhitecoats.com/IMG/dev/oeil-barre.png"
                className="Oeil"
                onClick={this.handleChangeOeil}
              />
            ) : (
              <img
                src="https://thewhitecoats.com/IMG/dev/oeil.png"
                className="Oeil"
                onClick={this.handleChangeOeil}
              />
            )}
          </div>
        </div>
        <div className="Champs">*Champs obligatoire</div>

        <div className="btnValider" onClick={this.handleSubmit}>
          {" "}
          JE ME CONNECTE
        </div>

        <div className="reinitialiser" onClick={this.handlePopup}>
          {" "}
          Réinitialiser mon mot de passe.
        </div>
        <div style={{ color: "transparent" }}>.</div>
        {templateReinitilalisation}
        {templateAlerte}
      </div>
    );
  }
}

export default Login;
